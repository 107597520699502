import React, {useContext, useMemo, useState } from "react";
import "./Mouvements.scss";
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { ModelContext } from "providers/ModelProvider";
import { DateTime } from "luxon";
import ModFlag from 'components/Mouvements/ModFlag';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { saveAs } from 'file-saver';
import Utils from 'Utils/Utils';


function Flags() {
  const [ flag, setFlag ]=useState(null);
  const { post,modele } = useContext(ModelContext);
  const flags=useMemo(()=>[...(modele.flags || [])].sort((a,b)=>b.id-a.id),[modele.flags]);
  const etatXlsx=(f)=>{
    post('flag-xlsx',f,(res)=>{
      var blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      saveAs(blob, `etat-stock-${Utils.filter(f.nom)}.xlsx`);
    },true,'blob');
  }
  return <>
        <div className='spacer'></div>
        <Container maxWidth="md">
          {flags.map((f)=><React.Fragment key={f.id} >
            <Card>
              <CardContent onClick={()=>setFlag(f)} style={{cursor:'pointer'}}>
                <Typography variant="h2">
                  {f.nom}
                </Typography>
                <Typography variant="h4" gutterBottom>
                  <small className='flag-key'>Date :</small> {new DateTime.fromISO(f.created_at, { zone: "utc" }).toLocaleString(DateTime.DATETIME_SHORT)}
                </Typography>
              </CardContent>
              <CardActions>
                <Button variant="contained" onClick={()=>etatXlsx(f)}>XLSX</Button>
              </CardActions>
            </Card>
            <div className='spacer'></div>
          </React.Fragment>)}
        </Container>
    <ModFlag flag={flag} onClose={()=>setFlag(null)}/>
  </>;
}

export default Flags;
