import React, { useState, useCallback } from "react";
import './Image.scss';

function Image({url,ratio=1}) {
  const [ loaded, setLoaded ] = useState(false);
  const [ error, setError ] = useState(false);
  const handleLoad=useCallback(()=>{
    setLoaded(true);
    setError(false);
  },[setLoaded])
  const handleError=useCallback(()=>{
    setError(true);
  },[setError]);
  return <div className={"image-container"+(loaded ? ' loaded' : '')} style={{paddingBottom:(ratio*100)+'%'}}>
      {Boolean(url) && !url.endsWith('/images/.jpg') ? <>
        <img alt="" src={url} crossOrigin="anonymous" onLoad={handleLoad} onError={handleError}/>
        {error && <div className="image-placeholder"/>}
      </> : <div className="image-placeholder"/>}
  </div>;
}

export default Image;
