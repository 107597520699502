import React, { useState, useContext } from "react";
import "./Fournisseur.scss";
import Button from '@mui/material/Button';
import Text from 'components/form/Text';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ModelContext } from "providers/ModelProvider";

function FournisseurAdd({open,handleClose}) {
  const { post } = useContext(ModelContext);
  const [ addFournisseur, setAddFournisseur] = useState({Nom:''});
  const validateNom=(v)=>{
    if (!v || v.length===0) return {test:false,message:'Veuillez saisir un nom'};
    return {test:true};
  }
  const valide=validateNom(addFournisseur.Nom).test;
  const handleOk=()=>{
    if (valide && addFournisseur) post('fournisseurs',addFournisseur,()=>{
      handleClose();
      setAddFournisseur({Nom:''});
    });
  }
  const handleChange=(k,v)=>{
    setAddFournisseur((state)=>{return{...state,[k]:v}});
  }
  return <Dialog open={Boolean(open)} onClose={handleClose} fullWidth maxWidth='xs'>
      <DialogTitle>Nouveau fournisseur</DialogTitle>
      <DialogContent>
        <div className="mini-spacer"></div>
        <Text name='Nom' value={addFournisseur.Nom} autoFocus handleChange={(v)=>handleChange('Nom',v)} handleSubmit={handleOk} validate={validateNom}/>
        <div className="mini-spacer"></div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">Annuler</Button>
        <Button disabled={!valide} onClick={handleOk} variant="contained">Ajouter</Button>
      </DialogActions>
    </Dialog>;
}

export default FournisseurAdd;
