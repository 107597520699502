import React, {useContext, useCallback} from "react";
import "./Produits.scss";
import { SettingsContext } from "providers/SettingsProvider";
import Chip from '@mui/material/Chip';
import Autocomplete from 'components/form/Autocomplete';

function FamillesChooser({familles}) {
  const { appState, setAppState, screenSize } = useContext(SettingsContext);
  const handleFamilleToggle=useCallback((famille)=>{
    setAppState((state)=>{
      const produitsFamilles=state.produitsFamilles;
      const idx=produitsFamilles.findIndex((o)=>o.Code===famille.Code);
      if (idx===-1) produitsFamilles.push(famille);
      else produitsFamilles.splice(idx,1);
      return{...state,produitsPage:1,produitsFamilles:[...produitsFamilles]}
    });
  },[setAppState]);
  const handleFamillesChange=useCallback((produitsFamilles)=>{
    setAppState((state)=>{
      return{...state,produitsPage:1,produitsFamilles:[...produitsFamilles]}
    });
  },[setAppState]);
  const famillesValue=appState.produitsFamilles;
  return <div className="familles-chooser">
    {['xs','sm'].indexOf(screenSize)!==-1 && <Autocomplete name='Familles' inputDisabled value={famillesValue} multiple handleChange={(v)=>handleFamillesChange(v)} options={familles} valueKey='Code' labelKey='Libelle'/>}
    {['xs','sm'].indexOf(screenSize)===-1 && familles.map((famille)=><Chip onClick={()=>handleFamilleToggle(famille)} key={famille.id} label={famille.Libelle} color="primary" variant={(famillesValue.findIndex((o)=>o.Code===famille.Code)!==-1 ? "filled" : "outlined")} />)}
  </div>;
}

export default FamillesChooser;
