import React from "react";
import "./DateInput.scss";
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';

function DateInput({value,name='',handleChange}) {
  return (
    <div className="input-text">
      <DatePicker
        label={name}
        value={value}
        onChange={handleChange}
        renderInput={(params) => <TextField fullWidth {...params} />}
      />
    </div>
  )
}

export default DateInput;
