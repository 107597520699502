import React, {useContext} from "react";
import "./Home.scss";
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { AuthContext } from "providers/AuthProvider";
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import {
  Link,
} from "react-router-dom";

function Home() {
  const { auth } = useContext(AuthContext);
  const { modele } = useContext(ModelContext);
  const { setAppState } = useContext(SettingsContext);
  const paniers=modele.paniers || [];
  const paniersNew=paniers.filter((o)=>o.status==='new');
  const paniersPending=paniers.filter((o)=>o.status==='pending');
  const paniersReady=paniers.filter((o)=>o.status==='ready');
  const handlePanierNew=()=>{
    setAppState(state=>{return{...state,paniersTab:0}});
  }
  const handlePanierPending=()=>{
    setAppState(state=>{return{...state,paniersTab:1}});
  }
  const handlePanierReady=()=>{
    setAppState(state=>{return{...state,paniersTab:2}});
  }
  return <Container maxWidth="lg">
    <div className="spacer"/>
    <Typography variant="h2" component="h2">
      Bonjour <strong>{auth.name}</strong>
    </Typography>
    <div className='big-spacer'></div>
    {paniersNew.length>0 && <Link to='/paniers' onClick={handlePanierNew}><Card>
      <CardContent className="panier-content">
        <Typography variant="h2">
          {paniersNew.length===1 && <><span className="highlight">{paniersNew.length}</span> nouveau panier à préparer</>}
          {paniersNew.length>1 && <><span className="highlight">{paniersNew.length}</span> nouveaux paniers à préparer</>}
        </Typography>
      </CardContent>
    </Card></Link>}
    {paniersPending.length>0 && <div className='spacer'></div>}
    {paniersPending.length>0 && <Link to='/paniers' onClick={handlePanierPending}><Card>
      <CardContent className="panier-content">
        <Typography variant="h2">
          {paniersPending.length===1 && <><span className="highlight">{paniersPending.length}</span> panier en cours de préparation</>}
          {paniersPending.length>1 && <><span className="highlight">{paniersPending.length}</span> paniers en cours de préparation</>}
        </Typography>
      </CardContent>
    </Card></Link>}
    {paniersReady.length>0 && <div className='spacer'></div>}
    {paniersReady.length>0 && <Link to='/paniers' onClick={handlePanierReady}><Card>
      <CardContent className="panier-content">
        <Typography variant="h2">
          {paniersReady.length===1 && <><span className="highlight">{paniersReady.length}</span> panier prêt</>}
          {paniersReady.length>1 && <><span className="highlight">{paniersReady.length}</span> paniers prêts</>}
        </Typography>
      </CardContent>
    </Card></Link>}
  </Container>;
}

export default Home;
