import React, {useContext, useState} from "react";
import "./Panier.scss";
import Button from '@mui/material/Button';
import { ModelContext } from "providers/ModelProvider";
import CircularProgress from '@mui/material/CircularProgress';
import { saveAs } from 'file-saver';

function ExportPdfListe({status}) {
  const { post } = useContext(ModelContext);
  const [ pdfRunning, setPdfRunning ] = useState(false);
  const pdf=()=>{
    setPdfRunning(true);
    post('pdf-panier-liste',{status},(res)=>{
      setPdfRunning(false);
      var blob = new Blob([res.data], {
        type: 'application/pdf'
      });
      saveAs(blob, `panier-liste-${status.value}.pdf`);
    },true,'blob');
  }
  return <Button onClick={pdf} variant="contained" disabled={pdfRunning}>
    Pdf {pdfRunning && <CircularProgress size={16} style={{marginLeft:10}}/>}
  </Button>;
}

export default ExportPdfListe;
