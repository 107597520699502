import React, {useContext, useMemo, useCallback, useEffect, useState }from "react";
import "./Mouvements.scss";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { ModelContext } from "providers/ModelProvider";
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { SettingsContext } from "providers/SettingsProvider";
import Text from 'components/form/Text';
import DateInput from 'components/form/DateInput';
import Autocomplete from 'components/form/Autocomplete';
import { DateTime } from "luxon";
import { saveAs } from 'file-saver';
import Button from '@mui/material/Button';

function Totaux() {
  const { post,modele,setContexts } = useContext(ModelContext);
  const { appState, setAppState } = useContext(SettingsContext);
  const { familles, date_debut, date_fin, texte }=appState.totaux;
  const { page, perPage }=appState.paginationTotaux;
  const params = useDebounce(appState.totaux, 500);
  const handleChange=useCallback((k,v)=>{
    setAppState((state)=>{
      return{...state,totaux:{...state.totaux,[k]:v}}
    });
  },[setAppState]);
  const handleChangeRowsPerPage = (event) => {
    setAppState((state)=>{
      return{...state,paginationTotaux:{page:0,perPage:parseInt(event.target.value, 10)}}
    });
  };
  const handleChangePage = useCallback((event, page) => {
    setAppState((state)=>{
      return{...state,paginationTotaux:{...state.paginationTotaux,page}}
    });
  },[setAppState]);
  useEffect(()=>{
    const { familles, date_debut, date_fin, texte }=params;
    setContexts((state)=>{
      const idx=state.findIndex((o)=>o.type==='totaux');
      const params={
        familles:familles.map((o)=>o.Libelle),
        date_debut:DateTime.fromJSDate(date_debut).toISODate(),
        date_fin:DateTime.fromJSDate(date_fin).toISODate(),
        texte
      };
      return [...state.slice(0,idx),{...state[idx],params},...state.slice(idx+1)];
    });
    handleChangePage(null,0);
  },[setContexts, handleChangePage,params]);
  const famillesList=useMemo(()=>[...(modele.familles || [])].sort((a,b)=>a.Libelle.localeCompare(b.Libelle)),[modele.familles]);
  const totaux=useMemo(()=>[...(modele.totaux || [])],[modele.totaux]);
  const etatXlsx=()=>{
    const params={
      familles:familles.map((o)=>o.Libelle),
      date_debut:DateTime.fromJSDate(date_debut).toISODate(),
      date_fin:DateTime.fromJSDate(date_fin).toISODate(),
      texte
    };
    post('totaux-xlsx',params,(res)=>{
      var blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      saveAs(blob, `totaux.xlsx`);
    },true,'blob');
  }
  return <>
        <div className='spacer'></div>
        <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Text name='Recherche' value={texte} handleChange={(v)=>handleChange('texte',v)}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete name='Familles' inputDisabled value={familles} multiple handleChange={(v)=>handleChange('familles',v)} options={famillesList} valueKey='Code' labelKey='Libelle'/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateInput name='Date de debut' value={date_debut} handleChange={(v)=>handleChange('date_debut',v)}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateInput name='Date de fin' value={date_fin} handleChange={(v)=>handleChange('date_fin',v)}/>
          </Grid>
        </Grid>
        {<>
          <Button variant="contained" onClick={etatXlsx}>XLSX</Button>
          <div className='spacer'></div>
        </>}
        {totaux.length>perPage && <TablePagination
         component="div"
         count={totaux.length}
         rowsPerPage={perPage}
         page={page}
         onRowsPerPageChange={handleChangeRowsPerPage}
         onPageChange={handleChangePage}
         />}
        {totaux && <TableContainer component={Paper}>
          <Table aria-label="Stock Chantier">
           <TableHead className="chantier-lignes-header">
             <TableRow>
             <TableCell>Produit</TableCell>
             <TableCell>Code</TableCell>
             <TableCell>Fabricant</TableCell>
             <TableCell>Famille</TableCell>
             <TableCell align="right">Quantité</TableCell>
             </TableRow>
           </TableHead>
           <TableBody>
           {totaux.filter((o,i)=>i>=page*perPage && i<(page+1)*perPage).map((total,i) => {
             return <TableRow
                 key={i}
                 sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
               >
               <TableCell component="th" scope="row">
                 {total.Libelle}
               </TableCell>
               <TableCell component="th" scope="row">
                 {total.CODE}
               </TableCell>
               <TableCell>
                   {total.Fabricant}
                 </TableCell>
                 <TableCell>
                  {total.Famille}
                 </TableCell>
                 <TableCell sx={{width:'1em'}} align="right">
                  {parseFloat(total.qte)}
                 </TableCell>
               </TableRow>;
             })}
             </TableBody>
           </Table>
         </TableContainer>}
         {totaux.length>perPage && <TablePagination
          component="div"
          count={totaux.length}
          rowsPerPage={perPage}
          page={page}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
          />}
      </Container>
  </>;
}
// Hook
function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}
export default Totaux;
