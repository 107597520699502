import React, {useState,useContext,useEffect} from "react";
import "./Mouvements.scss";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ModelContext } from "providers/ModelProvider";
import Text from 'components/form/Text';
import { confirm } from 'components/Confirm'

function ModFlag({flag,onClose}) {
  const { post, del } = useContext(ModelContext);
  const [ modFlag,setModFlag ]=useState(flag);
  useEffect(()=>setModFlag(flag),[flag]);
  const handleChange=(k,v)=>{
    setModFlag((state)=>{return{ ...state,[k]:v }});
  }
  const handleSave=()=>{
    console.log('save');
    post('flags',modFlag);
    onClose();
  }
  const handleDelete=()=>{
    confirm({
      titre: 'Supprimer le marque page ?',
      confirmation: <>Êtes vous sûr de vouloir supprimer <strong>{flag.nom}</strong>&nbsp;?</>,
    }).then((ok)=>{
      if (ok) {
        del('flags',flag.id);
        onClose();
      }
      else console.log('canceled');
    });
  }
  const handleClose=()=>{
    onClose();
  }
  return <Dialog
    fullWidth
    maxWidth='xs'
    open={Boolean(flag)}
    onClose={handleClose}
    className="mod-flag"
    >
      {modFlag && <>
        <DialogTitle>
          Modifier le marque-page
        </DialogTitle>
        <DialogContent>
          <Text name='Nom' autoFocus value={modFlag.nom} handleChange={(v)=>handleChange('nom',v)} handleSubmit={handleSave}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Fermer
          </Button>
          <Button onClick={handleDelete} variant="outlined">
            Supprimer
          </Button>
          <Button onClick={handleSave} disabled={!modFlag.nom} variant="contained">
            Enregistrer
          </Button>
        </DialogActions>
      </>}
    </Dialog>;
}

export default ModFlag;
