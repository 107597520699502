import React, {useContext,useState,useEffect,useCallback,useMemo} from "react";
import "./Mouvements.scss";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import SelectMui from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { SettingsContext } from "providers/SettingsProvider";
import _ from 'lodash';

function HistoriqueFilter({columns}) {
  const { appState,setAppState } = useContext(SettingsContext);
  const [ localFilters,setLocalFilters] = useState(appState.filterModel.items);
  const handleAppStateChange=useCallback((localFilters)=>{
    setAppState(state=>{ return {...state, filterModel:{items:localFilters}}});
  },[setAppState]);
  const debounced=useMemo(()=>_.debounce(handleAppStateChange,500),[handleAppStateChange]);
  useEffect(()=>{
    debounced(localFilters);
  },[localFilters,debounced]);
  const handleChange=(idx,k,v)=>{
    setLocalFilters((state)=>{
      return [
        ...state.slice(0,idx),
        {...state[idx],[k]:v},
        ...state.slice(idx+1)
      ];
    });
  }
  const addFilter=()=>{
    setLocalFilters((state)=>{
      return [
        ...state,
        {key:columns[1].field,value:''},
      ];
    });
  }
  const deleteFilter=(idx)=>{
    setLocalFilters((state)=>{
      return [
        ...state.slice(0,idx),
        ...state.slice(idx+1)
      ];
    });
  }
  return <div>
    <Button onClick={addFilter}>Nouveau filtre</Button>
    <div className='spacer'></div>
    <Grid container spacing={2}>
      {localFilters.map((filter,i)=><Grid item xs={2} key={i}>
        <FormControl fullWidth>
          <InputLabel>Colonne</InputLabel>
          <SelectMui
          value={filter.key}
          label='Colonne'
          native
          onChange={(e)=>handleChange(i,'key',e.target.value)}
          >
            {columns.map((column)=><option key={column.field} value={column.field}>{column.headerName}</option>)}
          </SelectMui>
        </FormControl>
        <div className='spacer'></div>
        <TextField
        fullWidth
        label='Rechercher'
        variant="outlined"
        value={filter.value}
        type='text'
        onChange={(e)=>handleChange(i,'value',e.target.value)}
        />
        <div className='spacer'></div>
        <Button onClick={()=>deleteFilter(i)}>Supprimer</Button>
      </Grid>)}
    </Grid>
  </div>
}

export default HistoriqueFilter;
