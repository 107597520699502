import Conf from 'Conf';
import { createTheme } from '@mui/material/styles';
import { frFR } from '@mui/material/locale';
const theme = createTheme({
  palette: {
    primary: {
      main: '#b4a365',
      contrastText:'#FFF',
    },
    secondary: {
      main: '#504c4a',
      contrastText:'#FFF',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
},frFR);
theme.typography.h1.fontSize='3rem';
theme.typography.h3[theme.breakpoints.down('md')]= {
  fontSize: '2.5rem',
};
theme.typography.h3.fontSize='1.5rem';
theme.typography.h3[theme.breakpoints.down('md')]= {
  fontSize: '1.2rem',
};
theme.typography.h4.fontSize='1.2rem';
theme.typography.h4[theme.breakpoints.down('md')]= {
  fontSize: '1rem',
};

const Utils={
    theme,
    accentsTidy:function(s='') {
        var map = [
            ["[àáâãäåāăąǎǟǡǻȁȃȧ]", "a"],
            ["[æ]", "ae"],
            ["[ƀƃɓ]", "b"],
            ["[çćĉċčƈȼɕ]", "c"],
            ["[ďđƌȡɖɗ]", "d"],
            ["[èéêëēĕėęěȅȇȩɇ]", "e"],
            ["[ƒ]", "f"],
            ["[ĝğġģǥǧǵɠ]", "g"],
            ["[ĥħȟɦ]", "h"],
            ["[ìíîïĩīĭįǐȉȋɨ]", "i"],
            ["[ĵǰɉʝ]", "j"],
            ["[ķƙǩ]", "k"],
            ["[ĺļľŀłƚȴɫɬɭ]", "l"],
            ["[ɱ]", "m"],
            ["[ñńņňƞǹȵɲɳ]", "n"],
            ["[òóôõöøōŏőơǒǫǭǿȍȏȫȭȯȱ]", "o"],
            ["[œ]", "oe"],
            ["[ƥ]", "p"],
            ["[ɋʠ]", "q"],
            ["[ŕŗřȑȓɍɼɽɾ]", "r"],
            ["[śŝşšșȿʂ]", "s"],
            ["[ţťŧƫƭțȶʈ]", "t"],
            ["[ùúûüũūŭůűųưǔǖǘǚǜȕȗ]", "u"],
            ["[ʋ]", "v"],
            ["[ŵ]", "w"],
            ["[ýÿŷƴȳɏ]", "y"],
            ["[źżžƶǅȥɀʐʑ]", "z"],
        ];
        for (var i=0; i<map.length; ++i) {
            s = s.replace(new RegExp(map[i][0], "gi"), ((j)=> {
                return (match) => {
                    if (match.toUpperCase() === match) {
                        return map[j][1].toUpperCase();
                    } else {
                        return map[j][1];
                    }
                }
            })(i));
        }
        return s;
    },
    accentsTidyLw:(s)=>{
        return Utils.accentsTidy(s).toLowerCase();
    },
    filter:(s)=>{
      return Utils.accentsTidyLw(s).replace(/[^a-zA-Z0-9]/g,'-');
    },
    basename:(str, sep)=>{
      return str.substr(str.lastIndexOf(sep) + 1);
    },
    chemin2url:(chemin)=>{
      return Conf.filesUrl+Utils.basename(chemin,'\\')+'.jpg';
    },
    panierLignesMvt:(panier,produits)=>panier && panier.lignes.filter((ligne)=>{
      let produit=produits.find((o)=>o.id===ligne.id_produit);
      produit=produit?produit:ligne.produit;
      const qMvt=-(panier.mvts.find((o)=>o.CODEEAN===produit.CODEEAN) || {q:0}).q;
      return ligne.qte_prep-qMvt!==0
    }).map((ligne)=>{
      let produit=produits.find((o)=>o.id===ligne.id_produit);
      produit=produit?produit:ligne.produit;
      const qMvt=-(panier.mvts.find((o)=>o.CODEEAN===produit.CODEEAN) || {q:0}).q;
      const stock=produit.stock || 0;
      const qte=ligne.qte_prep-qMvt;
      return{
        id_produit:ligne.id_produit,
        produit:ligne.produit,
        QTE:Math.abs(qte),
        MVT:qte>0 ? (stock===0 ? 'ENTREE CHANTIER' : 'RETOUR STOCK') : (stock===0 ? 'SORTIE CHANTIER' : 'ENTREE STOCK'),
      }
    }),
}
export default Utils;
