import React, { useState, useContext, useEffect } from "react";
import "./Salarie.scss";
import Button from '@mui/material/Button';
import Text from 'components/form/Text';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ModelContext } from "providers/ModelProvider";

function SalarieMod({salarie,handleClose}) {
  const { post } = useContext(ModelContext);
  const [modSalarie, setModSalarie] = useState({});
  const validateNom=(v)=>{
    if (!v || v.length===0) return {test:false,message:'Veuillez saisir un nom'};
    return {test:true};
  }
  const valide=validateNom(modSalarie.Nom).test;
  const handleOk=()=>{
    if (valide && salarie && salarie.id) post('salaries',modSalarie,()=>{
      handleClose();
    });
  }
  const handleChange=(k,v)=>{
    setModSalarie((state)=>{return{...state,[k]:v}});
  }
  useEffect(()=>{
    if (salarie) setModSalarie(salarie);
  },[salarie]);
  return <Dialog open={Boolean(salarie)} onClose={handleClose} fullWidth maxWidth='xs'>
      <DialogTitle>Modifier le salarié</DialogTitle>
      {salarie && <DialogContent>
        Login : <strong>{modSalarie.salariename}</strong>
        <div className="mini-spacer"></div>
        <Text name='Nom' autoFocus value={modSalarie.Nom} handleChange={(v)=>handleChange('Nom',v)} handleSubmit={handleOk} validate={validateNom}/>
        <div className="mini-spacer"></div>
      </DialogContent>}
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">Annuler</Button>
        <Button disabled={!valide} onClick={handleOk} variant="contained">Modifier</Button>
      </DialogActions>
    </Dialog>;
}

export default SalarieMod;
