import React, {useState,useEffect,useContext,useRef,useMemo,useCallback} from "react";
import "./Mouvements.scss";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Utils from 'Utils/Utils';
import Image from 'components/Image';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ModelContext } from "providers/ModelProvider";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Text from 'components/form/Text';
import Autocomplete from 'components/form/Autocomplete';
import Conf from 'Conf';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import { confirm } from 'components/Confirm'
import StockChip from 'components/Produits/StockChip';

const skel={
  CODE:'',
  CODEEAN:'',
  QTE:0,
  Commentaire:'',
  Libelle:'',
}
const customProduct={
  Code:'',
  CODEEAN:'999',
  Libelle:'',
  LibelleStd:'',
  PA:0,
  Famille:0,
  custom:true,
  CheminImage:'',
}
const chantierSkel={
  Libelle:'',
  NomClient:''
};
const fieldsMap={
  Fournisseur:{collection:'fournisseurs',itemKey:'Fournisseur',fieldKey:'id',labelKey:'Nom'},
  Chantier:{collection:'chantiers',itemKey:'Chantier',fieldKey:'id',labelKey:'Code/NomClient/Libelle'},
}
function Mouvement({newItem,onClose,lignesInit=[],id_panier=null}) {
  const { post,modele } = useContext(ModelContext);
  const [ item,setItem ]=useState(null);
  const [ ligne,setLigne ]=useState(skel);
  const [ produit,setProduit ]=useState('');
  const [ errProduit,setErrProduit ]=useState('');
  const [ lignes,setLignes ]=useState([]);
  const [ step,setStep ]=useState(0);
  const [ chantierCustom,setChantierCustom ]=useState({
    Libelle:'',
    NomClient:''
  });
  const needClient=(code)=>{
    const c=''+code;
    return c.endsWith('0031')
    || c.endsWith('ALAR');
  }
  const init=useRef(false);
  const eanRef=useRef(null);
  const qteRef=useRef(null);
  const ligneRef=useRef(null);
  const lignesInitRef=useRef(null);
  const mvt=useMemo(()=>item ? Conf.mvts.find((o)=>o.value===item.MVT) : null,[item]);
  const canGoNext=mvt ? mvt.fields.reduce((acc,v)=>acc && Boolean(item[v]),Boolean(item.Salarie)) && (mvt.fields.indexOf('Chantier')===-1 || !needClient(item.Chantier) || (chantierCustom.Libelle && chantierCustom.NomClient && chantierCustom.Ref)) : false;
  const produits=useMemo(()=>mvt && mvt.value==='MVT INVENTAIRE' ? (modele.produits || []) : [...(modele.produits || []),customProduct],[modele.produits,mvt]);
  useEffect(()=>{
    if (newItem && !init.current) {
      init.current=true;
      setItem(newItem);
    }
  },[newItem,setItem]);
  const handleChange=(k,v)=>{
    setItem((state)=>{return{ ...state,[k]:v }});
  }
  const handleChangeChantierCustom=(k,v)=>{
    setChantierCustom((state)=>{
      return{ ...state,[k]:v }
    });
  }
  const goQte=()=>{
    if (produit) qteRef.current.focus();
  }
  const handleChangeLigne=(k,v)=>{
    setErrProduit('')
    if (k==='QTE' && mvt.value!=='MVT INVENTAIRE') v=Math.abs(v);
    if (k==='CODEEAN') {
      v=v.trim();
      setLigne((state)=>{return{ ...state,[k]:v,CODE:'' }});
      return;
    }
    if (k==='CODE') {
      v=v.trim();
      setLigne((state)=>{return{ ...state,[k]:v,CODEEAN:parseInt(state.CODEEAN)===999?state.CODEEAN:'' }});
      return;
    }
    setLigne((state)=>{return{ ...state,[k]:v }});
  }
  const handleSave=()=>{
    console.log('save');
    post('mouvements',{mvts:lignes.map((ligne)=>{return{...ligne,id_panier}})});
    onClose();
    reset();
  }
  const reset=()=>{
    init.current=false;
    lignesInitRef.current=null;
    setStep(0);
    setItem(null);
    setLigne(skel);
    setLignes([]);
    setErrProduit('')
    setProduit(null);
    setChantierCustom(chantierSkel);
  }
  const annule=()=>{
    setLigne(skel);
    setErrProduit('')
    setProduit(null);
  }
  const handleClose=()=>{
    if (lignes.length>0) {
      confirm({
        titre: 'Fermer ?',
        confirmation: <>Il y a des mouvements en attente de validation. Fermer quand même&nbsp;?</>,
      }).then((ok)=>{
        if (ok) {
          reset();
          onClose();
        } else console.log('canceled');
      });
    } else {
      reset();
      onClose();
    }
  }
  const handleModLigne=(l)=>{
    setLigne({...l,QTE:mvt.mult*l.QTE});
    const produit=produits.find((o)=>o.CODEEAN===l.CODEEAN);
    if (produit) {
      setErrProduit('');
      setProduit(produit);
    }
  }
  const handleDeleteLigne=(l)=>{
    const tab=[...lignes];
    const idx = tab.findIndex((o)=>o.CODEEAN===l.CODEEAN && o.CODE===l.CODE);
    if (idx!==-1) {
      tab.splice(idx,1);
      setLignes(tab);
    }
  }
  useEffect(()=>{
    if (
      mvt
      && produits
      && (
        !ligneRef.current
        || (
          ligne.CODE!==ligneRef.current.CODE
          || ligne.CODEEAN!==ligneRef.current.CODEEAN
        )
      )
    ) {
      if (parseInt(ligne.CODEEAN)===999) {
        const l=lignes.find((o)=>o.CODEEAN===ligne.CODEEAN && o.CODE===ligne.CODE);
        console.log(l);
        if (l) {
          setErrProduit('');
          setProduit(customProduct);
          setLigne(state=>{return{...state,Libelle:l.Libelle,QTE:l.QTE/mvt.mult,Commentaire:l.Commentaire}});
        } else {
          setErrProduit('');
          setProduit(customProduct);
          setLigne(state=>{return{...state,Libelle:'',QTE:0,Commentaire:''}});
        }
      } else {
        let p=null;
        if(!ligneRef.current || ligne.CODEEAN!==ligneRef.current.CODEEAN) p=ligne.CODEEAN!=='' ? produits.find((o)=>o.CODEEAN===ligne.CODEEAN) : null;
        if(!ligneRef.current || ligne.CODE!==ligneRef.current.CODE) p=ligne.CODE!=='' ? produits.find((o)=>o.Code===ligne.CODE) : null;
        console.log(p);
        if (p) {
          setErrProduit('')
          setProduit(p)
          setLigne(state=>{return{...state,Libelle:p.LibelleStd,Commentaire:'',CODE:p.Code,CODEEAN:p.CODEEAN}});
          const l=lignes.find((o)=>o.CODEEAN===ligne.CODEEAN);
          if (l) {
            setLigne(state=>{return{...state,QTE:l.QTE/mvt.mult,Commentaire:l.Commentaire}});
          }
        } else {
          setProduit(null)
          setLigne(state=>{return {...skel,CODEEAN:state.CODEEAN||'',CODE:state.CODE||''}});
          setErrProduit('Produit inconnu.')
        }
      }
    }
    ligneRef.current=ligne;
  },[ligne,setLigne,lignes,setErrProduit,setProduit,mvt,produits]);
  const addLine=()=>{
    if (item && mvt && produit && Math.abs(ligne.QTE)>0) {
      const chantier=parseChantier(item.Chantier);
      const ligneMvt={
        CODE:ligne.CODE,
        CODEEAN:ligne.CODEEAN,
        Libelle:ligne.Libelle,
        Famille:produit.Libelle,
        MVT:item.MVT,
        QTE:ligne.QTE*mvt.mult,
        PA:produit.PA,
        Montant:Math.round(ligne.QTE*mvt.mult*produit.PA*100)/100,
        Fournisseur: item.Fournisseur ? modele.fournisseurs.find((o)=>o.id===item.Fournisseur).Nom : null,
        Chantier: chantier ? chantier.Code : null,
        LibelleChantier: chantier ? chantier.Libelle : null,
        NomClient: chantier ? chantier.NomClient : null,
        Salarie: item.Salarie ? modele.salaries.find((o)=>o.id===item.Salarie).Nom : null,
        Commentaire:ligne.Commentaire,
        Fabricant:produit.FABRIQUANT,
      }
      setLignes(state=>{
        const idx=state.findIndex((o)=>{
          return o.CODEEAN===ligneMvt.CODEEAN && o.CODE===ligneMvt.CODE
        });
        if (idx===-1) return [...state,ligneMvt];
        else {
          let tab=[...state];
          tab[idx]=ligneMvt;
          return tab;
        }
      });
      setLigne(skel);
      setProduit(null);
      eanRef.current.focus();
    }
  }
  const salaries=[...(modele.salaries || [])].sort((a,b)=>a.Sort-b.Sort);
  const salarie=salaries.find((o)=>item && item.Salarie===o.id);
  const getField=(field)=>{
    const {collection,itemKey,fieldKey}=fieldsMap[field];
    return modele[collection].find((o)=>item && item[itemKey]===o[fieldKey]);
  }
  const getLabelKey=(field)=>{
    const {labelKey}=fieldsMap[field];
    return labelKey;
  }
  const getOptions=(field)=>{
    const {collection}=fieldsMap[field];
    return field==='Chantier' ? [...(modele[collection].filter(o=>o.Etat!=='A') || [])].sort((a,b)=>a.Sort-b.Sort) : (modele[collection] || []);
  }
  const handleFieldChange=(field)=>(v)=>{
    const {itemKey,fieldKey}=fieldsMap[field];
    handleChange(itemKey,v ? v[fieldKey] : null);
  }
  const parseChantier=useCallback((id)=>{
    const c=modele.chantiers.find((o)=>o.id===id);
    if (!c) return c;
    else {
      const chantier={...c};
      if (needClient(chantier.Code) && chantierCustom.NomClient) chantier.NomClient=chantierCustom.NomClient;
      if (needClient(chantier.Code) && chantierCustom.Libelle) chantier.Libelle=chantierCustom.Libelle;
      return chantier;
    }
  },[modele,chantierCustom]);
  useEffect(()=>{
    if (item && produits && mvt && lignesInit.length>0 && lignesInit!==lignesInitRef.current && modele.salaries) {
      lignesInitRef.current=lignesInit;
      const chantier=parseChantier(item.Chantier);
      const newLignes=lignesInit.map((ligne)=>{
        let produit=produits.find((o)=>o.id===ligne.id_produit);
        produit=produit?produit:ligne.produit;
        return {
          CODE:produit.Code,
          CODEEAN:produit.CODEEAN,
          Libelle:produit.LibelleStd,
          Famille:produit.Libelle,
          MVT:item.MVT,
          QTE:ligne.QTE*mvt.mult,
          PA:produit.PA,
          Montant:Math.round(ligne.QTE*mvt.mult*produit.PA*100)/100,
          Fournisseur: null,
          Chantier: chantier ? chantier.Code : null,
          LibelleChantier: chantier ? chantier.Libelle : null,
          NomClient: chantier ? chantier.NomClient : null,
          Salarie: item.Salarie ? modele.salaries.find((o)=>o.id===item.Salarie).Nom : null,
          Commentaire:ligne.Commentaire,
          Fabricant:produit.FABRIQUANT,
        }
      });
      console.log(newLignes);
      setLignes(newLignes);
    }
  },[lignesInit,produits,parseChantier,item,mvt,modele.salaries]);
  return <Dialog
    fullWidth
    maxWidth='lg'
    open={Boolean(newItem)}
    onClose={handleClose}
    className="mod-mouvement"
    >
      {item && mvt && <>
        <DialogTitle>
          {mvt.label}
        </DialogTitle>
        <DialogContent>
          {step===0 && <>
            <Autocomplete name='Salarié' autoFocus value={salarie} inputDisabled handleChange={(v)=>handleChange('Salarie',v ? v.id : null)} options={salaries} labelKey='Nom' handleSubmit={()=>canGoNext && setStep(1)}/>
            {mvt.fields.map((field)=>{
              const fieldItem=modele.chantiers.find((o)=>o.id===item[field]);
              if (field!=='Chantier') return <Autocomplete key={field} inputDisabled name={field} value={getField(field)} handleChange={handleFieldChange(field)} options={getOptions(field)} labelKey={getLabelKey(field)} handleSubmit={()=>canGoNext && setStep(1)}/>;
              else return <React.Fragment key={field}>
                <Autocomplete name={field} value={getField(field)} handleChange={handleFieldChange(field)} options={getOptions(field)} labelKey={getLabelKey(field)} handleSubmit={()=>canGoNext && setStep(1)}/>
                {fieldItem && needClient(fieldItem.Code) && <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Text name='Nom du client' value={chantierCustom.NomClient} handleChange={(v)=>handleChangeChantierCustom('NomClient',v)}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Text name='Référence chantier' value={chantierCustom.Libelle} handleChange={(v)=>handleChangeChantierCustom('Libelle',v)}/>
                    </Grid>
                  </Grid>
                </>}
              </React.Fragment>
            })}
          </>}
          {canGoNext && step===1 && <>
            <span className='produit-key'>Salarié :</span> {modele.salaries.find((o)=>o.id===item.Salarie).Nom}
            {mvt.fields.map((field)=>{
              const chantier=parseChantier(item.Chantier);
              const fournisseur= modele.fournisseurs.find((o)=>o.id===item[field]);
              return <div key={field}>
                {field==='Fournisseur' && fournisseur  && <><span className='produit-key'>Fournisseur :</span> {fournisseur.Nom}</>}
                {field==='Chantier' && chantier && <><span className='produit-key'>Chantier :</span> {chantier.Code} - {chantier.Libelle}<br /></>}
                {field==='Chantier' && chantier && <><span className='produit-key'>Client :</span> {chantier.NomClient}</>}
              </div>;
            })}
            <div className="spacer"/>
            <Typography variant="h3">
              Ajouter / Modifier un mouvement
            </Typography>
            {mvt.value!=='MVT INVENTAIRE' ? <Grid container spacing={1}>
              <Grid item xs={9}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Text name='Code EAN' inputRef={eanRef} autoFocus value={ligne.CODEEAN} handleChange={(v)=>handleChangeLigne('CODEEAN',v)} handleSubmit={goQte}/>
                  </Grid>
                  <Grid item xs={4}>
                    <Text name='Code Fournisseur' value={ligne.CODE} handleChange={(v)=>handleChangeLigne('CODE',v)} handleSubmit={goQte}/>
                  </Grid>
                  <Grid item xs={4}>
                    <Text selectOnFocus name='Quantité' inputRef={qteRef} type='number' value={ligne.QTE} handleChange={(v)=>handleChangeLigne('QTE',v)} handleSubmit={addLine}/>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Text name={errProduit || 'Libelle'} value={ligne.Libelle} handleChange={(v)=>handleChangeLigne('Libelle',v)} readOnly={!produit || !produit.custom} handleSubmit={addLine}/>
                  </Grid>
                  <Grid item xs={12}>
                    <Text name='Commentaire' value={ligne.Commentaire} handleChange={(v)=>handleChangeLigne('Commentaire',v)} handleSubmit={addLine}/>
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button onClick={annule} disabled={!produit} variant="outlined">
                    Annuler
                  </Button>
                  <Button onClick={addLine} disabled={!produit || !ligne.CODE || !Math.abs(ligne.QTE)>0} variant="contained">
                    Ajouter
                  </Button>
                </DialogActions>
              </Grid>
              <Grid item xs={3} className='image-grid'>
                <div>
                <Image url={Utils.chemin2url(produit ? produit.CheminImage : '')}/>
                <StockChip produit={produit}/>
                </div>
                <div className="mvt-produit-commentaire">{produit && produit.LibelleTec}</div>
              </Grid>
            </Grid> : <Grid container spacing={1}>
              <Grid item xs={9}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Text name='Code EAN' inputRef={eanRef} autoFocus value={ligne.CODEEAN} handleChange={(v)=>handleChangeLigne('CODEEAN',v)} handleSubmit={goQte}/>
                  </Grid>
                  <Grid item xs={4}>
                    <Text name='Code Fournisseur' value={ligne.CODE} handleChange={(v)=>handleChangeLigne('CODE',v)} handleSubmit={goQte}/>
                  </Grid>
                  <Grid item xs={4}>
                    <Text selectOnFocus name='Nouveau stock' inputRef={qteRef} type='number' value={produit ? parseFloat(produit.stock || 0)+parseFloat(ligne.QTE) : ligne.QTE} handleChange={(v)=>handleChangeLigne('QTE',produit ? parseFloat(v)-parseFloat(produit.stock || 0) : ligne.QTE)} handleSubmit={addLine}/>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Text name={errProduit || 'Libelle'} value={ligne.Libelle} handleChange={(v)=>handleChangeLigne('Libelle',v)} readOnly={!produit || !produit.custom} handleSubmit={addLine}/>
                  </Grid>
                  <Grid item xs={12}>
                    <Text name='Commentaire' value={ligne.Commentaire} handleChange={(v)=>handleChangeLigne('Commentaire',v)} handleSubmit={addLine}/>
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button onClick={annule} disabled={!produit} variant="outlined">
                    Annuler
                  </Button>
                  <Button onClick={addLine} disabled={!produit || !Math.abs(ligne.QTE)>0} variant="contained">
                    Ajouter
                  </Button>
                </DialogActions>
              </Grid>
              <Grid item xs={3} className='image-grid'>
                <div>
                <Image url={Utils.chemin2url(produit ? produit.CheminImage : '')}/>
                <StockChip produit={produit}/>
                </div>
                <div className="mvt-produit-commentaire">{produit && produit.LibelleTec}</div>
              </Grid>
            </Grid>}
            <div className="spacer"/>
              <Typography variant="h3">
                Liste des mouvements à ajouter
              </Typography>
              <div className="spacer"/>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="Mouvements à ajouter" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Ref</TableCell>
                      <TableCell>Libelle</TableCell>
                      <TableCell>Commentaire</TableCell>
                      <TableCell align="right">Quantité</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lignes.length===0 && <TableRow
                        key='vide'
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell colSpan={4} align="center">(Aucun mouvement)</TableCell>
                      </TableRow>}
                    {lignes.map((l,i) => (
                      <TableRow
                        key={l.CODEEAN+'/'+l.CODE}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          <IconButton size="small" aria-label="modifier" onClick={()=>handleModLigne(l)} color="inherit">
                            <EditIcon fontSize="small"/>
                          </IconButton>
                          <IconButton size="small" aria-label="modifier" onClick={()=>handleDeleteLigne(l)} color="inherit">
                            <DeleteIcon fontSize="small"/>
                          </IconButton>
                        </TableCell>
                        <TableCell>{l.CODE}</TableCell>
                        <TableCell>{l.Libelle}</TableCell>
                        <TableCell>{l.Commentaire}</TableCell>
                        <TableCell align="right">{l.QTE}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
          </>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Fermer
          </Button>
          {step === 0 &&<Button onClick={()=>setStep(1)} disabled={!canGoNext} variant="contained">
            Suivant
          </Button>}
          {step === 1 && <Button onClick={handleSave} disabled={lignes.length===0} variant="contained">
            Valider le(s) mouvement(s)
          </Button>}
        </DialogActions>
      </>}
    </Dialog>;
}

export default Mouvement;
