import React, {useContext, useMemo, useCallback, useState, useEffect, useRef} from "react";
import { SettingsContext } from "providers/SettingsProvider";
import Text from 'components/form/Text';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import _ from 'lodash';

function ProduitsForm() {
  const { appState,setAppState } = useContext(SettingsContext);
  const [ filter, setFilter ] = useState(appState.produitsFilter);
  const filterCache=useRef(filter);
  const setAppStateFilter=useCallback((v)=>{
    setAppState((state)=>{return{...state,produitsPage:1,produitsFilter:v}});
  },[setAppState]);
  const debouncedSetFilter=useMemo(()=>_.debounce(setAppStateFilter,300),[setAppStateFilter]);
  useEffect(()=>{
    debouncedSetFilter(filter);
    filterCache.current=filter;
  },[filter,debouncedSetFilter]);
  const handleFilterChange=useCallback((v)=>{
    setFilter(v);
  },[setFilter]);
  useEffect(()=>{
    if (filterCache.current!==appState.produitsFilter) setFilter(appState.produitsFilter);
  },[appState.produitsFilter]);
  const { mode, produitsStock } = appState;
  const setMode=()=>{
    setAppState(state=>{return{...state,mode:mode==='and'?'or':'and'}});
  }
  const setProduitsStock=(e,v)=>{
    if (v) setAppState(state=>{return{...state,produitsStock:v}});
  }
  return <>
    <Text
    name='Rechercher'
    value={filter}
    handleChange={handleFilterChange}
    clear
    />
    <ToggleButtonGroup
      className="produits-toggle"
      color="primary"
      value={mode}
      exclusive
      onChange={setMode}
      aria-label="Mode"
      size="small"
    >
      <ToggleButton value="and">tous les mots</ToggleButton>
      <ToggleButton value="or">Au moins un mot</ToggleButton>
    </ToggleButtonGroup> <ToggleButtonGroup
      className="produits-toggle"
      color="primary"
      value={produitsStock}
      exclusive
      onChange={setProduitsStock}
      aria-label="Stock"
      size="small"
    >
      <ToggleButton value="all">tous les produits</ToggleButton>
      <ToggleButton value="stock">En stock</ToggleButton>
      <ToggleButton value="outOfStock">Rupture</ToggleButton>
      <ToggleButton value="negative">Négatif</ToggleButton>
    </ToggleButtonGroup>
  </>
}

export default ProduitsForm;
