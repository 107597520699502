import React, {useRef,useEffect,useState,useContext} from "react";
import "./Autocomplete.scss";
import AutocompleteMui from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Utils from 'Utils/Utils';
import { SettingsContext } from "providers/SettingsProvider";

function Autocomplete({name='',inputDisabled=false,value=null,valueKey='value',labelKey='label',autoFocus=false,handleChange,options=[],multiple=false,validate=()=>true,handleSubmit=()=>{},isOptionEqualToValue=(o,v)=>o===v}) {
  const { screenSize } = useContext(SettingsContext);
  const [open,setOpen] = useState(false);
  inputDisabled=inputDisabled && Boolean(screenSize==='xs');
  const handleChangeLocal=(e,v)=>{
    handleChange(v);
    setOpen(false);
  }
  const input=useRef(null);
  useEffect(()=>{
    if (input.current && inputDisabled) {
      const node=input.current
      const blur=()=>{
        node.blur();
      }
      node.addEventListener('focus',blur);
      return ()=>{
        node.removeEventListener('focus',blur);
      }
    }
  },[inputDisabled]);
  const handleClick=(e)=>{
    if (inputDisabled) {
      //e.defaultMuiPrevented = true;
      console.log('click',e.target===input.current,open)
      if (e.target===input.current) setOpen(state=>!state);
    }
  }
  const handleOpen=(e)=>{
    if (inputDisabled) {
      const {type}=e;
      if (['mousedown'].indexOf(type)===-1) setOpen(true);
    } else {
      setOpen(true);
    }
  }
  const handleClose=(e,r)=>{
    if (inputDisabled) {
      if (!(e.target===input.current && ['blur','toggleInput'].indexOf(r)!==-1)) setOpen(false);
    } else {
      setOpen(false);
    }
  }
  return (
    <div className="input-select">
        <AutocompleteMui
        fullWidth
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        value={value}
        label={name}
        multiple={multiple}
        onChange={handleChangeLocal}
        getOptionLabel={(o)=>{
          const tab=labelKey.split('/');
          return tab.map((k)=>o[k]||'').join(' - ');
        }}
        filterOptions={(options,state)=>options.filter((o)=>{
          const tab=labelKey.split('/');
          return state.inputValue.length===0 || tab.reduce((acc,k)=>acc || Utils.accentsTidyLw(o[k]).indexOf(Utils.accentsTidyLw(state.inputValue)) !==-1,false);
        })}
        isOptionEqualToValue={isOptionEqualToValue}
        options={options}
        onKeyPress={(e)=>e.which===13 && handleSubmit()}
        blurOnSelect
        renderInput={(params) => <TextField {...params} onClick={handleClick} autoFocus={!inputDisabled && autoFocus} label={name} inputRef={input}/>}
        />
    </div>
  )
}

export default Autocomplete;
