import React, {useContext, useState} from "react";
import "./Produits.scss";
import Button from '@mui/material/Button';
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import CircularProgress from '@mui/material/CircularProgress';
import { saveAs } from 'file-saver';

function ExportPdf({disabled=false}) {
  const { post } = useContext(ModelContext);
  const { appState } = useContext(SettingsContext);
  const [ pdfRunning, setPdfRunning ] = useState(false);
  const pdf=()=>{
    setPdfRunning(true);
    post('pdf',{mode:appState.mode,produitsStock:appState.produitsStock,filter:appState.produitsFilter,familles:appState.produitsFamilles.map((o)=>o.Code)},(res)=>{
      setPdfRunning(false);
      var blob = new Blob([res.data], {
        type: 'application/pdf'
      });
      saveAs(blob, `export-produits.pdf`);
    },true,'blob');
  }
  return <Button onClick={pdf} variant="contained" disabled={pdfRunning || disabled}>
    Export pdf {pdfRunning && <CircularProgress size={16} style={{marginLeft:10}}/>}
  </Button>;
}

export default ExportPdf;
