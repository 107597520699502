import React, {useContext, useMemo, useState, useCallback } from "react";
import "./Mouvements.scss";
import { ModelContext } from "providers/ModelProvider";
import { DateTime } from "luxon";
import AddFlag from 'components/Mouvements/AddFlag';
import ModFlag from 'components/Mouvements/ModFlag';
import IconButton from '@mui/material/IconButton';
import UndoIcon from '@mui/icons-material/Undo';
import FlagIcon from '@mui/icons-material/Flag';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import { confirm } from 'components/Confirm'
import Tooltip from '@mui/material/Tooltip';
import HistoriqueFilter from './HistoriqueFilter';
import HistoriqueListe from './HistoriqueListe';
import Conf from 'Conf';

function Historique() {
  const [ flag, setFlag ]=useState(null);
  const [ ligneId, setLigneId ]=useState(null);
  const [ clicked, setClicked ]=useState(null);
  const { post,modele } = useContext(ModelContext);
  const flags=useMemo(()=>[...(modele.flags || [])].sort((a,b)=>b.id-a.id),[modele.flags]);
  const setSelected=useCallback((params)=>{
    setClicked(state=>state===params.row.id ? null : params.row.id);
  },[setClicked]);
  const revert=useCallback((id)=>{
      confirm({
        titre: 'Annuler le mouvement ?',
        confirmation: <>Ceci créera un nouveau mouvement avec la quantité opposée. Voulez-vous continuer&nbsp;?</>,
      }).then((ok)=>{
        if (ok) {
          const mvt=modele.mouvements.find((o)=>o.id===id);
          if (mvt) {
            const revertMvt={...mvt, reverted:undefined, id:undefined, created_at:undefined, QTE:-1*mvt.QTE, Montant:-1*mvt.Montant, annule:id};
            post('mouvements',{mvts:[revertMvt]});
          }
        } else console.log('canceled');
      });
  },[modele.mouvements,post]);
  const addFlag=useCallback((id)=>{
      setLigneId(id);
  },[setLigneId]);
  const modFlag=useCallback((id)=>{
    const f=flags.find((o)=>o.id_mouvement===id);
    if (f) setFlag(f);
  },[setFlag,flags]);
  const columns = useMemo(()=>[
    { field: 'action', headerName: '', width: 100, sortable:false,renderCell: (params) =>{
      const flag=flags.find((o)=>o.id_mouvement===params.row.id);
      return <>
        {!params.row.reverted && <IconButton aria-label="menu" onClick={()=>revert(params.id)} color="inherit">
          <UndoIcon />
        </IconButton>}
        {Boolean(params.row.reverted) && <IconButton aria-label="menu" disabled color="inherit">
          <UndoIcon />
        </IconButton>}
        {flag ?
        <Tooltip title={flag.nom} placement="right">
          <IconButton aria-label="menu" onClick={()=>modFlag(params.row.id)} color="inherit">
            <FlagIcon />
          </IconButton>
        </Tooltip>
        :
        <IconButton aria-label="menu" onClick={()=>addFlag(params.row.id)} color="inherit">
          <FlagOutlinedIcon />
        </IconButton>}
      </>
    }},
    { field: 'created_at', headerName: 'Date', width: 150, sortable:false, valueFormatter:(params)=>new DateTime.fromISO(params.value, { zone: "utc" }).toLocaleString(DateTime.DATETIME_SHORT) },
    { field: 'MVT', headerName: 'Type', width: 200, sortable:false,renderCell: (params) =>
      Conf.mvts.find((mvt)=>mvt.value===params.value).labelShort
    },
    { field: 'Chantier', headerName: 'Code Chantier', width: 150, sortable:false },
    { field: 'LibelleChantier', headerName: 'Libelle Chantier', width: 250, sortable:false },
    { field: 'CODE', headerName: 'Ref.', width: 150, sortable:false },
    { field: 'Fabricant', headerName: 'Fabricant', width: 150, sortable:false },
    { field: 'Libelle', headerName: 'Libelle', width: 350, sortable:false },
    { field: 'QTE', headerName: 'QTE', width: 80, sortable:false },
    { field: 'Commentaire', headerName: 'Commentaire', width: 150, sortable:false },
    { field: 'Salarie', headerName: 'Salarie', width: 150, sortable:false },
    { field: 'CODEEAN', headerName: 'EAN', width: 150, sortable:false },
    { field: 'Fournisseur', headerName: 'Fournisseur', width: 150, sortable:false },
    { field: 'Famille', headerName: 'Famille', width: 200, sortable:false },
    { field: 'NomClient', headerName: 'Client', width: 250, sortable:false },
    { field: 'PA', headerName: 'PA', width: 100, sortable:false, valueFormatter:(params)=>parseFloat(params.value).toLocaleString('fr-FR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })+"€" },
    { field: 'Montant', headerName: 'Montant', width: 100, sortable:false, valueFormatter:(params)=>parseFloat(params.value).toLocaleString('fr-FR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })+"€" },
    { field: 'Note', headerName: 'Note', width: 150, sortable:false },
    { field: 'id_panier', headerName: 'ID Panier', width: 150, sortable:false },
  ],[revert,modFlag,addFlag,flags]);
  return <>
    <HistoriqueFilter columns={columns}/>
    <div className='spacer'></div>
    <HistoriqueListe columns={columns} setSelected={setSelected} selected={clicked}/>
    <AddFlag ligneId={ligneId} onClose={()=>setLigneId(null)}/>
    <ModFlag flag={flag} onClose={()=>setFlag(null)}/>
  </>
}

export default Historique;
