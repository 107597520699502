import React, {useState,useEffect,useContext} from "react";
import "./Produits.scss";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Utils from 'Utils/Utils';
import Image from 'components/Image';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import Color from 'components/form/Color';
import AddToPanier from 'components/Paniers/AddToPanier';
import { ModelContext } from "providers/ModelProvider";
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import StockChip from './StockChip';
import { SettingsContext } from "providers/SettingsProvider";
import EAN from 'Utils/EAN';
import { AuthContext } from "providers/AuthProvider";
import Historique from './Historique';
import {CopyToClipboard} from 'react-copy-to-clipboard';

function Produit({onClose,produit}) {
  const { auth } = useContext(AuthContext);
  const { screenSize } = useContext(SettingsContext);
  const { post, setMessages} = useContext(ModelContext);
  const [ item,setItem ]=useState({Couleur:null});
  const [ add,setAdd ]=useState(null);
  useEffect(()=>{
    if (produit) {
      const { id, Couleur }=produit;
      setItem({ id, Couleur });
    }
  },[produit,setItem]);
  const handleChange=(k,v)=>{
    setItem((state)=>{return{ ...state,[k]:v }});
  }
  const handleSave=()=>{
    console.log('save');
    post('produits',item);
  }
  const resetCouleur=()=>{
    post('produits',{...item,Couleur:null});
  }
  const handleCopied=()=>setMessages(state=>{return [...state,{text:'Référence copiée !',severity:'success'}]});
  const saved=produit ? Object.keys(item).reduce((acc,k)=>acc && item[k]===produit[k],true) : true;
  return <>
  <Dialog
    fullWidth
    maxWidth={screenSize==='xs' ? undefined : 'md'}
    fullScreen={screenSize==='xs'}
    open={Boolean(produit)}
    onClose={onClose}
    scroll='body'
    aria-labelledby={produit ? produit.LibelleStd : ''}
    aria-describedby={produit ? produit.LibelleStd : ''}
    >
      {produit && <div style={{display:'flex',flexDirection:'column',minHeight:'100%'}}>
        <DialogTitle className='produit-head'>
          <span className='produit-titre'>{produit.LibelleStd || '(Sans nom)'}</span>
          {screenSize!=='xs' && <StockChip produit={produit}/>}
        </DialogTitle>
        <DialogContent style={{flexGrow:1}}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5}>
              {screenSize==='xs' && <div className='produit-stock-container'>
                <div className='produit-xs'><Image url={Utils.chemin2url(produit.CheminImage)}/></div>
                {screenSize==='xs' && <StockChip produit={produit}/>}
              </div>}
              {screenSize!=='xs' && <Image url={Utils.chemin2url(produit.CheminImage)}/>}
          </Grid>
            <Grid item xs={12} sm={7} className='produit-desc'>
              {produit.CODEEAN && <EAN ean={produit.CODEEAN}/>}
              <Typography variant="h4" gutterBottom>
                <small className='produit-key'>Fabriquant :</small> {produit.FABRIQUANT}
              </Typography>
              <CopyToClipboard text={produit.Code} onCopy={handleCopied}>
                <Typography variant="h4" gutterBottom className='ref'>
                    <small className='produit-key'>Référence :</small> {produit.Code}
                </Typography>
              </CopyToClipboard>
              <Typography variant="h4" gutterBottom>
                <small className='produit-key'>Position :</small> {produit.POSITION}
              </Typography>
              <Typography variant="h4" gutterBottom>
                <small className='produit-key'>Famille :</small> {produit.Libelle}
              </Typography>
              {['admin'].indexOf(auth.role)!==-1 && produit.PA && <Typography variant="h4" gutterBottom>
                <small className='produit-key'>
                  Prix d'achat :
                </small> {parseFloat(produit.PA).toLocaleString('fr-FR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}€
              </Typography>}
              <Historique historique={produit.historique}/>
            </Grid>
            <Grid item xs={12} className='produit-desc'>
              <Typography variant="h4" gutterBottom>
                <small className='produit-key'>Commentaire :</small> {produit.LibelleTec}
              </Typography>
            </Grid>
          </Grid>
          {['manager','admin'].indexOf(auth.role)!==-1 && <>
          <div className="spacer"></div>
          <Divider/>
          <div className="spacer"></div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Stack direction='row' justifyContent="flex-start" alignItems="center">
                Couleur :
                <Color value={item.Couleur} handleChange={(v)=>handleChange('Couleur',v)} onClose={!saved ? handleSave : undefined}/>
                {item.Couleur && <IconButton aria-label="menu" onClick={resetCouleur} color="inherit">
                  <CloseIcon />
                </IconButton>}
              </Stack>
            </Grid>
          </Grid>
          </>}
        </DialogContent>
        <DialogActions>
          {auth.role==='user' && <Button onClick={()=>setAdd(produit)} variant="contained">
            Ajouter au panier
          </Button>}
          <Button autoFocus onClick={onClose} variant="contained">
            Fermer
          </Button>
        </DialogActions>
      </div>}
    </Dialog>
    <AddToPanier produit={add} handleClose={()=>{setAdd(null);onClose();}}/>
    </>;
}

export default Produit;
