const Conf={
  apiUrl:process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "https://opus-api.bechard.fr/",
  filesUrl:process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL+"images/" : "https://opus-api.bechard.fr/images/",
  renewInterval:5*60*1000,
  roles:[
    {label:'Admin',value:'admin'},
    {label:'Gestionnaire',value:'manager'},
    {label:'Équipe',value:'user'},
  ],
  panierStatus:[
    {label:'Nouveau',value:'new'},
    {label:'En cours de préparation',value:'pending'},
    {label:'Prêt',value:'ready'},
    {label:'Enlevé',value:'done'},
    {label:'Corbeille',value:'deleted'},
  ],
  mvts:[
    {label:'Fournisseur -> Dépôt',labelShort:'Fourn -> Dépôt',value:'ENTREE FOURNISSEUR',fields:['Fournisseur'],mult:1,color:'#C2DED1'},
    {label:'Dépôt -> Fournisseur',labelShort:'Dépôt -> Fourn',value:'RETOUR FOURNISSEUR',fields:['Fournisseur'],mult:-1,color:'#C2DED1'},
    {label:'Chantier -> Dépôt',labelShort:'Cht -> Dépôt',value:'ENTREE STOCK',fields:['Chantier'],mult:1,color:'#C2DED1'},
    {label:'Dépôt -> Chantier',labelShort:'Dépôt -> Cht',value:'RETOUR STOCK',fields:['Chantier'],mult:-1,color:'#C2DED1'},
    {label:'Fournisseur -> Chantier',labelShort:'Fourn -> Cht',value:'ENTREE CHANTIER',fields:['Chantier','Fournisseur'],mult:1,color:'#C2DED1'},
    {label:'Chantier -> Fournisseur',labelShort:'Cht -> Fourn',value:'SORTIE CHANTIER',fields:['Chantier','Fournisseur'],mult:-1,color:'#C2DED1'},
    {label:'Modifier stock',labelShort:'Inventaire',value:'MVT INVENTAIRE',fields:[],mult:1,color:'#EEC373'},
  ],
}
export default Conf;
