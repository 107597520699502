import React, { useState, useContext, useMemo } from "react";
import "./Panier.scss";
import { SettingsContext } from "providers/SettingsProvider";
import { ModelContext } from "providers/ModelProvider";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Autocomplete from 'components/form/Autocomplete';
import Text from 'components/form/Text';
import TextLong from 'components/form/TextLong';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

function Panier() {
  const { modele,post } = useContext(ModelContext);
  const { appState,setPanier } = useContext(SettingsContext);
  const [ panierStatus,setPanierStatus ] = useState('edit');
  const produits=useMemo(()=>modele.produits || [],[modele.produits]);
  const panier=useMemo(()=>appState.panier,[appState.panier]);
  const handleChange=(k,v)=>{
    const p={...panier,[k]:v};
    setPanier(p);
  }
  const handleChangeLigne=(idx,k,v)=>{
    const p={...panier,lignes:[
      ...panier.lignes.slice(0,idx),
      {
        ...panier.lignes[idx],
        [k]:Math.max(0,parseFloat(v)),
      },
      ...panier.lignes.slice(idx+1)
    ]};
    setPanier(p);
  }
  const handleSave=()=>{
    setPanierStatus('saving');
    const {chantier,...others} = panier;
    if (panier && panier.lignes.length>0 && panier.chantier) post('paniers',{
      ...others,
      id_chantier:chantier.id,
      chantier_nom:chantier.Libelle,
      chantier_code:chantier.Code,
      chantier_nom_client:chantier.NomClient,
    },()=>{
      setPanierStatus('saved');
      setPanier({chantier:null,lignes:[]});
    });
  }
  const handleDeleteLigne=(idx)=>{
    const p={...panier,lignes:[
      ...panier.lignes.slice(0,idx),
      ...panier.lignes.slice(idx+1)
    ]};
    setPanier(p);

  }
  return <div className="panier">
    <Container maxWidth="lg" className="fournisseurs">
      <div className="spacer"/>
      <Typography variant="h1" component="h1">
        Mon panier
      </Typography>
      <div className='spacer'></div>
      {panierStatus!=='saved' ? <>
        {modele.chantiers && <Autocomplete name='Chantier' autoFocus value={panier.chantier} handleChange={(v)=>handleChange('chantier',v ? v : null)} options={modele.chantiers.filter(o=>o.Etat!=='A').map((o)=>{return {...o,label:o.Code+' - '+o.NomClient+' - '+o.Libelle}})} labelKey='label' isOptionEqualToValue={(o,v)=>o.id===v.id}/>}
        <div className='spacer'></div>
        <FormGroup>
          <FormControlLabel control={<Switch checked={Boolean(panier.urgent)} onChange={(e)=>handleChange('urgent',e.target.checked)} />} label={panier.urgent ? 'urgent' : 'pas urgent'} />
        </FormGroup>
        <div className='spacer'></div>
        <TableContainer component={Paper}>
          <Table aria-label="Panier">
            <TableHead className="panier-lignes-header">
              <TableRow>
                <TableCell>Produit</TableCell>
                <TableCell align="right">Quantité</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {panier.lignes.map((ligne,i) => {
              const produit=produits.find((o)=>ligne.produit && o.id===ligne.produit.id);
              return <TableRow
                  key={i}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {produit && produit.FABRIQUANT} - {produit && produit.Code} - {produit && produit.LibelleStd}
                  </TableCell>
                  <TableCell align="right" sx={{width:'8em'}}>
                    <Text name='' type='number' small value={ligne.QTE} handleChange={(v)=>handleChangeLigne(i,'QTE',v)}/>
                  </TableCell>
                  <TableCell sx={{width:'1em'}}>
                    <IconButton size="small" aria-label="modifier" onClick={()=>handleDeleteLigne(i)} color="inherit">
                      <DeleteIcon fontSize="small"/>
                    </IconButton>
                  </TableCell>
                </TableRow>;
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <div className='spacer'></div>
        <TextLong name='Commentaire' value={panier.commentaire} handleChange={(v)=>handleChange('commentaire',v)}/>
        <div className='spacer'></div>
        <Button onClick={handleSave} disabled={panierStatus!=='edit' || panier.lignes.length===0 || !panier.chantier} variant="contained">
          Valider le panier
        </Button>
      </>
      :
      <>
        Panier validé !
      </>}
    </Container>
  </div>;
}

export default Panier;
