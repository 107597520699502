import React, { useState, createContext, useEffect, useContext, useRef } from "react";
import Conf from 'Conf';
import { AuthContext } from "providers/AuthProvider";
import _ from 'lodash';

export const SettingsContext = createContext({});

const appStateSkel={
  produitsPage:1,
  produitsFilter:'',
  produitsFamilles:[],
  panier:{
    chantier:null,
    lignes:[],
    urgent:false,
  },
  paniersFilter:'',
  paniersTab:0,
  paniersPage:{},
  mouvementsTab:0,
  filterModel:{items:[]},
  columnVisibilityModel:{},
  totaux:{
    familles:[],
    date_debut:null,
    date_fin:null,
    texte:'',
  },
  paginationTotaux:{
    page:0,
    perPage:10,
  },
  mode:'and',
  produitsStock:'all',
};
//clean localstorage
if ('localStorage' in window) {
  if (localStorage.getItem('opus-panier')) localStorage.removeItem('opus-panier');
  if (localStorage.getItem('opus-h')) localStorage.removeItem('opus-h');
  if (localStorage.getItem('opus-filterModel')) localStorage.removeItem('opus-filterModel');
  if (localStorage.getItem('opus-columnVisibilityModel')) localStorage.removeItem('opus-columnVisibilityModel');
}

const SettingsProvider = ({children})=>{
  const { auth } = useContext(AuthContext);
  const [ screenSize, setScreenSize ] = useState("xxl");
  const [ appState,setAppState ] = useState(appStateSkel);
  const userId=useRef(0);
  const setPanier=(panier)=>{
    setAppState(state=>{
      return{...state,panier};
    });
  }
  useEffect(()=>{
    if(auth.id!==userId.current) {
      if (auth.id) {
        if('localStorage' in window) {
          const appStateLocalJson=localStorage.getItem('opus-appState/'+auth.id);
          const appStateLocal = JSON.parse(appStateLocalJson);
          if (appStateLocal) {
            console.log('AppState load',auth.id);
            setAppState({...appStateSkel,...appStateLocal});
          } else {
            console.log('AppStateSkel load',auth.id);
            setAppState(appStateSkel);
          }
        }
      } else {
        if('localStorage' in window) {
          console.log('AppStateSkel load',auth.id);
          setAppState(appStateSkel);
        }
      }
      userId.current=auth.id;
    }
  },[setAppState,auth]);
  useEffect(()=>{
    if (userId.current && appState && 'localStorage' in window) {
      const appStateLocalJson=localStorage.getItem('opus-appState/'+userId.current);
      const appStateLocal = JSON.parse(appStateLocalJson);
      if(!_.isEqual(appState,appStateLocal) && !_.isEqual(appState,appStateSkel)) {
        console.log('AppState store',userId.current);
        localStorage.setItem('opus-appState/'+userId.current,JSON.stringify(appState));
      }
    }
  },[appState]);
  useEffect(()=>{
    const resize=()=>{
      const width=document.body.clientWidth;
      let size="xs";
      if (width>576) size="sm";
      if (width>768) size="md";
      if (width>992) size="lg";
      if (width>1200) size="xl";
      if (width>1600) size="xxl";
      setScreenSize(size);
      ["xs","sm","md","lg","xl","xxl"].forEach((s) => {
        if (s!==size) document.body.classList.remove(s);
      });
      document.body.classList.add(size);
      console.log(width,size);
    }
    resize();
    window.addEventListener('resize',resize);
    window.addEventListener('pageshow',resize);
    return ()=>{
      window.removeEventListener('resize',resize);
      window.removeEventListener('pageshow',resize);
    }
  },[setScreenSize]);
  return (
      <SettingsContext.Provider value={{appState,setAppState,Conf,screenSize,setPanier}}>
          {children}
      </SettingsContext.Provider>
  );
}
export default SettingsProvider;
