import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
import "./Panier.scss";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ModelContext } from "providers/ModelProvider";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Conf from 'Conf';
import Select from 'components/form/Select';
import { DateTime } from "luxon";
import Typography from '@mui/material/Typography';
import { AuthContext } from "providers/AuthProvider";
import { SettingsContext } from "providers/SettingsProvider";
import Text from 'components/form/Text';
import ExportPdf from 'components/Paniers/ExportPdf';
import Autocomplete from 'components/form/Autocomplete';
import Messages from './Messages';
import { createPatch, applyPatch } from 'rfc6902';
import _ from 'lodash';
import Mouvement from 'components/Mouvements/Mouvement';
import { confirm } from 'components/Confirm'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import CheckIcon from '@mui/icons-material/Check';
import Utils from 'Utils/Utils';

const mvtsSaisie=['RETOUR STOCK','ENTREE STOCK','SORTIE CHANTIER','ENTREE CHANTIER'];

function PanierMod({panierId,onClose}) {
  const [ newItem,setNewItem ]=useState(null);
  const { screenSize,setAppState } = useContext(SettingsContext);
  const { auth } = useContext(AuthContext);
  const { modele,post,del } = useContext(ModelContext);
  const [modPanier, setModPanier] = useState({});
  const lastPanier=useRef({});
  const panier=useMemo(()=>modele.paniers ? modele.paniers.find((o)=>o.id===panierId) || {} : {},[modele.paniers,panierId]);
  const produits=useMemo(()=>modele.produits || [],[modele.produits]);
  const users=useMemo(()=>modele.users || [],[modele.users]);
  const salaries=useMemo(()=>modele.salaries || [],[modele.salaries]);
  const chantiers=useMemo(()=>modele.chantiers || [],[modele.chantiers]);
  const goToNext=()=>{
    if (panier.id_next) setAppState(state=>{return{...state,modPanier:panier.id_next}});
  }
  const goToPrev=()=>{
    if (panier.id_parent) setAppState(state=>{return{...state,modPanier:panier.id_parent}});
  }
  const handleOk=(event,cb=()=>{})=>{
    if (modPanier && modPanier.id) post('paniers',modPanier,cb);
  }
  const handleNext=()=>{
    if (modPanier && modPanier.id) confirm({
      titre: 'Créer le panier ?',
      confirmation: <>Voulez-vous créer un panier de reliquat avec les produits non encore préparés&nbsp;?</>,
    }).then((ok)=>{
      if (ok) {
        post('paniers|panier-next',{id:panierId});
      } else console.log('canceled');
    });
  }
  const handleDelete=()=>{
    confirm({
      titre: 'Supprimer définitivement ?',
      confirmation: <>Voulez-vous supprimer définitivement le panier&nbsp;? Cette opération est irréversible.</>,
    }).then((ok)=>{
      if (ok) {
        setAppState(state=>{return{...state,modPanier:null}});
        del('paniers',panierId);
      } else console.log('canceled');
    });
  }
  const handleOkClose=()=>{
    handleOk(null,onClose);
  }
  const handleClose=()=>{
    if (!_.isEqual(modPanier,panier)) {
      confirm({
        titre: 'Fermer ?',
        confirmation: <>Il y a des modifications non enregistrées. Fermer quand même&nbsp;?</>,
      }).then((ok)=>{
        if (ok) {
          onClose();
          setModPanier({});
          lastPanier.current={};
        } else console.log('canceled');
      });
    } else {
      onClose();
    }
  }
  const handleChange=(k,v)=>{
    setModPanier((state)=>{return{...state,[k]:v}});
  }
  useEffect(()=>{
    if(!_.isEqual(panier,lastPanier.current)) {
      const newPanier=_.cloneDeep(modPanier);
      const patch=createPatch(lastPanier.current,panier);
      applyPatch(newPanier,patch);
      lastPanier.current=_.cloneDeep(panier);
      setModPanier(newPanier);
    }
  },[panier,modPanier,setModPanier]);
  const user=modPanier && users.find((o)=>o.id===modPanier.user_id);
  const chantier=modPanier && chantiers.find((o)=>o.id===modPanier.id_chantier);
  const handleChangeLigne=(idx,k,v)=>{
    setModPanier((state)=>{
      let newLignes=[...state.lignes.map((o)=>{return {...o}})];
      const ligne=newLignes[idx];
      let produit=produits.find((o)=>o.id===ligne.id_produit);
      produit=produit?produit:ligne.produit;
      if (k==='qte_prep') {
        const stock=produit.stock || 0;
        if (stock>0) newLignes[idx][k]=Math.min(stock,Math.max(0,parseFloat(v)));
        else newLignes[idx][k]=Math.max(0,parseFloat(v));
      }
      return{...state,lignes:newLignes};
    });
  }
  const threadId=useMemo(()=>panierId ? 'panier_'+panierId : 'none',[panierId]);
  const handleMvt=(MVT)=>{
    console.log('handleMvtStock',modPanier);
    setNewItem({
        MVT,
        Fournisseur:null,
        Chantier:modPanier.id_chantier,
        Salarie:null,
    });
  }
  const lignes=useMemo(()=>panier && panier.lignes ? Utils.panierLignesMvt(panier,produits) : [],[produits,panier]);
  const isComplete=useMemo(()=>{
    if (!panier || !panier.lignes) return false;
    for(const ligne of panier.lignes) {
      if(ligne.QTE!==ligne.qte_prep) return false;
    }
    return true;
  },[panier]);
  console.log(lignes);
  return <>
  <Dialog
  open={Boolean(panierId)}
  onClose={handleClose}
  fullWidth
  maxWidth={screenSize==='xs' ? undefined : 'lg'}
  fullScreen={screenSize==='xs'}
  PaperProps={{className:"mod-panier"}}
  >
      {Boolean(panierId) && panier && <DialogTitle>Panier n°{panier.id} {panier.urgent ? <Chip size="small" label="urgent" color="warning" /> : null}</DialogTitle>}
      {Boolean(panierId) && panier && modPanier && modPanier.id && <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
            <Typography variant="h4" gutterBottom>
              <small className='produit-key'>Chantier :</small> {chantier ? chantier.Code : modPanier.chantier_code} - {chantier ? chantier.NomClient : modPanier.chantier_nom_client} - {chantier ? chantier.Libelle : modPanier.chantier_nom}
            </Typography>
            <Typography variant="h4" gutterBottom>
              <small className='produit-key'>Utilisateur :</small> {(user && user.name) || modPanier.user_nom}
            </Typography>
            <Typography variant="h4" gutterBottom>
              <small className='produit-key'>Créé le :</small> {DateTime.fromISO(modPanier.created_at).toLocaleString(DateTime.DATE_SHORT)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            {['admin','manager'].indexOf(auth.role)!==-1 && <>
              <Select name='Statut' value={modPanier.status} handleChange={(v)=>handleChange('status',v)} options={Conf.panierStatus}/>
              {modPanier.status==='done' && <Autocomplete name='Enlevé par' inputDisabled value={salaries.find((o)=>o.id===modPanier.done_by)} handleChange={(v)=>handleChange('done_by',v ? v.id : null)} options={salaries} labelKey='Nom' valueKey='id'/>}
            </>}
          </Grid>
        </Grid>
        {['admin','manager'].indexOf(auth.role)!==-1 && <>
          <div className="spacer"/>
          <div className="actions">
            <ExportPdf panier={panier}/>
            {panier && ['ready','done'].indexOf(panier.status)!==-1 && <>
              {mvtsSaisie.map((m)=>lignes.filter((o)=>o.MVT===m).length>0 ? <Button key={m} variant="contained" color="primary" onClick={()=>handleMvt(m)}>Saisir {Conf.mvts.find((o)=>o.value===m).label}</Button>:'')}
              {lignes.length===0 && panier.mvts.length>0 && <Button variant="contained" color="primary" disabled ><CheckIcon/>Mouvements enregistrés</Button>}
            </>}
            {panier && !panier.id_next && !isComplete && ['ready','done'].indexOf(panier.status)!==-1 && <Button variant="contained" color="primary" onClick={handleNext}>Générer un panier avec le reliquat</Button>}
            {panier && (panier.id_parent || panier.id_next) && <p>
              {panier.id_parent && <>Reliquat du panier <span onClick={goToPrev} className="link">#{panier.id_parent}</span></>}
              {panier.id_parent && panier.id_next && <>, </>}
              {panier.id_next && <>Panier de reliquat : <span onClick={goToNext} className="link">#{panier.id_next}</span></>}
            </p>}
          </div>
        </>}
        <div className="spacer"/>
        <TableContainer component={Paper}>
          <Table aria-label="Panier">
            <TableHead className="panier-lignes-header">
              <TableRow>
                <TableCell>Fabricant</TableCell>
                <TableCell>Ref.</TableCell>
                <TableCell>Libelle</TableCell>
                <TableCell align="right">Quantité</TableCell>
                <TableCell align="right">Quantité préparée</TableCell>
                <TableCell align="right">Quantité saisie en mvt</TableCell>
                <TableCell align="right">Stock</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {modPanier.lignes.map((ligne,i) => {
                let produit=produits.find((o)=>o.id===ligne.id_produit);
                produit=produit?produit:ligne.produit;
                const qMvt=-(panier.mvts.find((o)=>o.CODEEAN===produit.CODEEAN) || {q:0}).q;
                return <TableRow
                  key={i}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {produit && produit.FABRIQUANT}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {produit && produit.Code}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {produit && produit.LibelleStd}
                  </TableCell>
                  <TableCell align="right" sx={{width:'8em'}}>
                      <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                        <strong>{ligne.QTE}</strong>
                        {auth.role!=='user'
                          && panier
                          && ['ready','done','deleted'].indexOf(panier.status)===-1
                          && <IconButton
                            onClick={()=>handleChangeLigne(i,'qte_prep',ligne.QTE)}
                            size="small"
                            style={{position:'relative',top:'-1px',marginLeft:'5px'}}>
                            <KeyboardDoubleArrowRightIcon/>
                          </IconButton>}
                      </div>
                  </TableCell>
                  <TableCell align="right" sx={{width:'8em'}}>
                    {auth.role!=='user' && panier && ['ready','done','deleted'].indexOf(panier.status)===-1 ? <Text name='Qte préparée' small type='number' value={ligne.qte_prep||0} handleChange={(v)=>handleChangeLigne(i,'qte_prep',v)}/>
                    : ligne.qte_prep
                    }
                  </TableCell>
                  <TableCell align="right" sx={{width:'8em'}}>
                    {qMvt}
                  </TableCell>
                  <TableCell align="right" sx={{width:'8em'}}>
                      {(produit && produit.stock) || 0}
                  </TableCell>
                </TableRow>;
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <div className='spacer'></div>
        {['admin','manager'].indexOf(auth.role)!==-1 && <>
          <DialogActions>
            {['deleted'].indexOf(panier.status)!==-1 && <Button onClick={handleDelete} variant="outlined">Supprimer definitivement</Button>}
            <Button onClick={handleClose} variant="outlined">Fermer</Button>
            <Button onClick={handleOk} variant="contained" disabled={modPanier && modPanier.status==='done' && !modPanier.done_by}>Enregistrer</Button>
            <Button onClick={handleOkClose} variant="contained" disabled={modPanier && modPanier.status==='done' && !modPanier.done_by}>Enregistrer et fermer</Button>
          </DialogActions>
        </>}
        {['user'].indexOf(auth.role)!==-1 && <>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined">Fermer</Button>
          </DialogActions>
        </>}
        <Messages threadId={threadId} userId={panier.id_user}/>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">Fermer</Button>
        </DialogActions>
        </DialogContent>}

      </Dialog>
      <Mouvement newItem={newItem} onClose={()=>setNewItem(null)} lignesInit={newItem?lignes.filter((o)=>o.MVT===newItem.MVT):[]} id_panier={panierId}/>
      </>;
}

export default PanierMod;
