import React, {useContext,useState,useMemo} from "react";
import "./Topbar.scss";
import { AuthContext } from "providers/AuthProvider";
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {
  NavLink,
  useNavigate,
} from "react-router-dom";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';
import Conf from 'Conf';
import ChatIcon from '@mui/icons-material/Chat';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import _ from 'lodash';

function Topbar() {
  const { post,modele } = useContext(ModelContext);
  const { auth, onLoggedOut } = useContext(AuthContext);
  const { screenSize,appState,setAppState } = useContext(SettingsContext);
  const [ mobileMenu,setMobileMenu] = useState(false);
  const [ refreshRunning, setRefreshRunning ] = useState(false);
  const [ messagesMenu, setMessagesMenu ] = useState(false);
  const panier=useMemo(()=>appState.panier,[appState.panier]);
  const nonlus=useMemo(()=>modele.nonlus,[modele.nonlus]);
  const nonlusByThread=useMemo(()=>{
    const o=_.groupBy(nonlus,'threadId');
    const tab=[];
    Object.keys(o).forEach((key) => {
      if (key.indexOf('panier_')!==-1) {
        const actualKey=parseInt(key.replace('panier_',''));
        tab.push({key:actualKey,nonlus:o[key]});
      }
    });
    return tab;
  },[nonlus]);
  const navigate=useNavigate();
  const handleGoToPanier=(id)=>{
    setMessagesMenu(null);
    navigate('/paniers');
    setAppState((state)=>{return {...state,modPanier:id}});
    const scroll=()=>{
      const node=document.querySelector(".mod-panier>div");
      if (node) {
        node.style.scrollBehavior='smooth';
        node.scrollTo(0,node.scrollHeight);
        node.style.scrollBehavior='auto';
      }
    }
    setTimeout(scroll,1000);
    setTimeout(scroll,2000);
  };
  const paniersNew=useMemo(()=>(modele.paniers||[]).filter((o)=>o.status===Conf.panierStatus[0].value),[modele.paniers]);
  const refresh=()=>{
    setRefreshRunning(true);
    post('updatedb',{},()=>{
      setRefreshRunning(false);
    });
  }
  return (
    <>
    <AppBar position="static" className="topbar" color="secondary">
      <Toolbar>
        {['xs','sm','md','lg'].indexOf(screenSize)!==-1 && <>
          <IconButton aria-label="menu" onClick={()=>setMobileMenu(true)} color="inherit">
            <MenuIcon />
          </IconButton>
        </>}
        <NavLink to="/"><span className="topbar-logo"/></NavLink>
        <Typography variant="h6" component="div" className="topbar-item" sx={{ flexGrow: 1 }}>
          {['xs','sm','md','lg'].indexOf(screenSize)===-1 && <>
            <NavLink to="/">Accueil</NavLink>
            <NavLink to="/produits">Produits</NavLink>
            {['manager','admin'].indexOf(auth.role)!==-1 && <NavLink to="/mouvements">Mouvements</NavLink>}
            {['manager','admin','user'].indexOf(auth.role)!==-1 && <NavLink to="/paniers">
              <Badge badgeContent={paniersNew.length} color="primary">
                Paniers
              </Badge>
            </NavLink>}
            {['manager','admin'].indexOf(auth.role)!==-1 && <NavLink to="/salaries">Salaries</NavLink>}
            {['manager','admin'].indexOf(auth.role)!==-1 && <NavLink to="/fournisseurs">Fournisseurs</NavLink>}
            {auth.role==='admin' && <NavLink to="/utilisateurs">Utilisateurs</NavLink>}
            {auth.role==='admin' && <IconButton aria-label="menu" onClick={refresh} color="inherit" disabled={refreshRunning}>
              {refreshRunning ? <CircularProgress size={16} style={{marginLeft:10}}/> : <RefreshIcon />}
            </IconButton>}
            {['manager','user'].indexOf(auth.role)!==-1 && <NavLink to="/profil">Mon profil</NavLink>}
          </>}
        </Typography>
        {nonlus && nonlus.length>0 && <>
          <IconButton onClick={(e)=>setMessagesMenu(e.currentTarget)} aria-label="messages" color="inherit" style={{marginLeft:10,marginRight:10}}>
            <Badge badgeContent={nonlus.length} color="primary">
              <ChatIcon size={16}/>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={messagesMenu}
            open={Boolean(messagesMenu)}
            onClose={()=>setMessagesMenu(null)}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {nonlusByThread.map((o)=><MenuItem key={o.key} onClick={()=>handleGoToPanier(o.key)}>
              <Badge badgeContent={o.nonlus.length} color="primary">{'Panier n°'+o.key}&nbsp;&nbsp;&nbsp;</Badge>
            </MenuItem>)}
          </Menu>
        </>}
        {['user'].indexOf(auth.role)!==-1 && <NavLink to="/panier">
          <IconButton aria-label="panier" color="inherit" style={{marginLeft:10,marginRight:10}}>
            <Badge badgeContent={panier.lignes.length} color="primary">
              <ShoppingCartIcon size={16}/>
            </Badge>
          </IconButton>
        </NavLink>}
        <div className="topbar-user"><small>{auth.name}</small></div>
        <IconButton aria-label="menu" onClick={onLoggedOut} color="inherit">
          <LogoutIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
    <Drawer
      anchor='left'
      open={mobileMenu}
      onClose={()=>setMobileMenu(false)}
      className='topbar-drawer'
    >
      <div className='topbar-drawer-header'>
        <IconButton aria-label="menu" onClick={()=>setMobileMenu(false)} color="inherit">
          <MenuIcon />
        </IconButton>
      </div>
      <List onClick={()=>setMobileMenu(false)}>
        <NavLink to="/">
          <ListItem button>
            <ListItemText primary="Accueil" />
          </ListItem>
        </NavLink>
        <NavLink to="/produits">
          <ListItem button>
            <ListItemText primary="Produits" />
          </ListItem>
        </NavLink>
        {['manager','admin'].indexOf(auth.role)!==-1 && <NavLink to="/mouvements">
          <ListItem button>
            <ListItemText primary="Mouvements" />
          </ListItem>
        </NavLink>}
        {['manager','admin','user'].indexOf(auth.role)!==-1 && <NavLink to="/paniers">
          <ListItem button>
            <ListItemText primary="Paniers" />
          </ListItem>
        </NavLink>}
        {['manager','admin'].indexOf(auth.role)!==-1 && <NavLink to="/salaries">
          <ListItem button>
            <ListItemText primary="Salaries" />
          </ListItem>
        </NavLink>}
        {['manager','admin'].indexOf(auth.role)!==-1 && <NavLink to="/fournisseurs">
          <ListItem button>
            <ListItemText primary="Fournisseurs" />
          </ListItem>
        </NavLink>}
        {auth.role==='admin' && <NavLink to="/utilisateurs">
          <ListItem button>
            <ListItemText primary="Utilisateurs" />
          </ListItem>
        </NavLink>}
        {['manager','user'].indexOf(auth.role)!==-1 && <NavLink to="/profil">
          <ListItem button>
            <ListItemText primary="Mon profil" />
          </ListItem>
        </NavLink>}
      </List>
    </Drawer>
    </>
  )
}

export default Topbar;
