import React from "react";
import Chip from '@mui/material/Chip';

function StockChip({produit}) {
  return produit ? <>{produit.stock>0 ?
    <Chip label={<>Stock : <span className='stock'>{produit.stock}</span></>} variant="outlined" color='primary' />
    :
    <Chip label={'épuisé'} style={{backgroundColor:produit.stock<0?'#F00':'#FFF',color:produit.stock<0?'#FFF':'none',borderColor:produit.stock<0?'#F00':'none'}} variant="outlined" color='primary' />
  }</> : '';
}

export default StockChip;
