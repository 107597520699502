import React, {useRef, useEffect, useContext} from "react";
import textFit from "textfit";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ModelContext } from "providers/ModelProvider";

function EAN ({ean}) {
  const { setMessages } = useContext(ModelContext);
  const container=useRef(true);
  useEffect(()=>{
    const resize=()=>{
      if (container.current) {
        textFit(container.current);
      }
    }
    window.addEventListener('resize',resize);
    resize();
    const timer=setTimeout(resize,1000);
    return ()=>{
      window.removeEventListener('resize',resize);
      clearTimeout(timer);
    }
  },[]);
  const handleCopied=()=>setMessages(state=>{return [...state,{text:'Code EAN copié !',severity:'success'}]});
  return <div className='ean'>
    <CopyToClipboard text={ean} onCopy={handleCopied}>
      <div className='ean-inner' ref={container}>
        <span style={{cursor:'pointer'}}>*{ean}*</span>
      </div>
    </CopyToClipboard>
  </div>
  ;
};
export default EAN;
