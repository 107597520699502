import React, {useContext, useState, useMemo, useCallback, useEffect, useRef} from "react";
import "./Paniers.scss";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ModelContext } from "providers/ModelProvider";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import PanierMod from 'components/Paniers/PanierMod';
import Conf from 'Conf';
import Chip from '@mui/material/Chip';
import { AuthContext } from "providers/AuthProvider";
import { SettingsContext } from "providers/SettingsProvider";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { DateTime } from "luxon";
import Text from 'components/form/Text';
import _ from 'lodash';
import Utils from 'Utils/Utils';
import Badge from '@mui/material/Badge';
import ExportPdfListe from 'components/Paniers/ExportPdfListe';
import Pagination from '@mui/material/Pagination';
import CheckIcon from '@mui/icons-material/Check';

const itemsPerPage=6;

const computePercent=(p)=>{
  if (p && p.lignes.length>0) {
    let total=0;
    let prep=0;
    p.lignes.forEach((ligne) => {
      total=total+parseFloat(ligne.QTE);
      prep=prep+parseFloat(ligne.qte_prep);
    });
    return total>0 ? Math.min(100,100*prep/total) : 0;
  } else {
    return 0;
  }
}

function Paniers() {
  const { auth } = useContext(AuthContext);
  const { modele } = useContext(ModelContext);
  const { appState,setAppState } = useContext(SettingsContext);
  const [ filter, setFilter ] = useState(appState.paniersFilter);
  const paginationTop=useRef(null);
  const filterCache=useRef(filter);
  const modPanier=appState.modPanier;
  const setModPanier=(p)=>{
    setAppState((state)=>{return{...state,modPanier:p}});
  }
  const setAppStateFilter=useCallback((v)=>{
    setAppState((state)=>{return{...state,paniersFilter:v}});
  },[setAppState]);
  const debouncedSetFilter=useMemo(()=>_.debounce(setAppStateFilter,300),[setAppStateFilter]);
  useEffect(()=>{
    debouncedSetFilter(filter);
    filterCache.current=filter;
  },[filter,debouncedSetFilter]);
  const handleFilterChange=useCallback((v)=>{
    const paniersPage={};
    Conf.panierStatus.forEach((status)=>{
      paniersPage[status.value]=1;
    });
    setFilter(v);
    setAppState((state)=>{return{...state,paniersPage}});
  },[setFilter,setAppState]);
  useEffect(()=>{
    if (filterCache.current && filterCache.current!==appState.paniersFilter) setFilter(appState.paniersFilter);
  },[appState.paniersFilter])
  const appStateFilter=useMemo(()=>appState.paniersFilter ,[appState.paniersFilter]);
  const paniers=useMemo(()=>modele.paniers || [],[modele.paniers]);
  const users=useMemo(()=>modele.users || [],[modele.users]);
  const chantiers=useMemo(()=>modele.chantiers || [],[modele.chantiers]);
  const salaries=useMemo(()=>modele.salaries || [],[modele.salaries]);
  const produits=useMemo(()=>modele.produits || [],[modele.produits]);
  const handleChange = (event, newValue) => {
    setAppState(state=>{return{...state,paniersTab:newValue}});
  };
  const filteredPaniers=useMemo(()=>paniers
  .filter((o)=>{
    let test = true;
    let tab = appStateFilter ? appStateFilter.split(' ') : [];
    tab.forEach((word, i) => {
      let wordTest=(
        Utils.accentsTidyLw(o.chantier_code||'').indexOf(Utils.accentsTidyLw(word)) !==-1
        || Utils.accentsTidyLw(o.chantier_nom||'').indexOf(Utils.accentsTidyLw(word)) !==-1
        || Utils.accentsTidyLw(o.chantier_nom_client||'').indexOf(Utils.accentsTidyLw(word)) !==-1
        || Utils.accentsTidyLw(o.user_nom||'').indexOf(Utils.accentsTidyLw(word)) !==-1
        || o.id+''===word.replace('#','')
      );
      let lignesTest = false;
      o.lignes.forEach((ligne, i) => {
        lignesTest = lignesTest || (
          Utils.accentsTidyLw(ligne.produit.Code||'').indexOf(Utils.accentsTidyLw(word)) !==-1
          || Utils.accentsTidyLw(ligne.produit.LibelleStd||'').indexOf(Utils.accentsTidyLw(word)) !==-1
          || Utils.accentsTidyLw(ligne.produit.CODEEAN||'').indexOf(Utils.accentsTidyLw(word)) !==-1
        );
      });
      wordTest=wordTest||lignesTest;
      test=test && wordTest;
    });
    return test;
  }),[paniers,appStateFilter]);
  const filteredPaniersMap=useMemo(()=>{
    const res={};
    Conf.panierStatus.forEach((status)=>{
      res[status.value]=filteredPaniers.filter((o)=>o.status===status.value)
    });
    return res;
  },[filteredPaniers]);
  const status=useMemo(()=>Conf.panierStatus[appState.paniersTab].value,[appState.paniersTab]);
  const list=useMemo(()=>filteredPaniersMap[status],[filteredPaniersMap,status]);
  const page=useMemo(()=>appState.paniersPage[status]||1 ,[appState.paniersPage,status]);
  const handlePageChange=useCallback((e,p)=>{
    setAppState((state)=>{return{...state,paniersPage:{...state.paniersPage,[status]:p}}});
    if(paginationTop.current) paginationTop.current.scrollIntoView();
  },[setAppState,status]);
  return <>
    <Container maxWidth="lg" className="paniers">
      <div className="spacer"/>
      <Typography variant="h1" component="h1">
        Paniers
      </Typography>
      <div className='spacer'></div>
      <Text
      name='Rechercher'
      value={filter}
      handleChange={handleFilterChange}
      clear
      />
      <div className='spacer'></div>
      <Tabs value={appState.paniersTab} onChange={handleChange} variant="scrollable"
  scrollButtons="auto">
        {Conf.panierStatus.filter((o)=>['admin','manager'].indexOf(auth.role)!==-1 || o.value!=='deleted').map((o)=><Tab key={o.value} label={
          o.value!=='deleted' ?
            <Badge badgeContent={filteredPaniersMap[o.value].length} color="primary">
              <span className='panier-types'>{o.label}</span>
            </Badge>
            : <span className='panier-types'>{o.label}</span>
          }/>)}
      </Tabs>
      {['admin','manager'].indexOf(auth.role)!==-1 && <>
        <div className="spacer"/>
        <div className="actions">
          <ExportPdfListe status={Conf.panierStatus[appState.paniersTab]}/>
        </div>
      </>}
      <div className='spacer'></div>
      {Math.ceil(list.length/itemsPerPage)>1 && <Pagination ref={paginationTop} size='small' count={Math.ceil(list.length/itemsPerPage)} page={page} onChange={handlePageChange}/>}
      <div className='spacer'></div>
      <Grid container spacing={2}>{list.filter((o,i)=>i>=(page-1)*itemsPerPage && i<page*itemsPerPage).map((panier)=>{
        const user=users.find((o)=>o.id===panier.user_id);
        const chantier=chantiers.find((o)=>o.id===panier.id_chantier);
        const status=Conf.panierStatus.find((o)=>o.value===panier.status).label;
        const lignes=Utils.panierLignesMvt(panier,produits);
        const p=computePercent(panier);
        return <Grid key={panier.id} item xs={12} md={6}>
          <Card onClick={()=>setModPanier(panier.id)} className="panier">
            <CardContent>
              <div  className="panier-content">
                <Typography variant="h5" component="div" className="grow">
                  Panier n°{panier.id} {panier.urgent ? <Chip size="small" label="urgent" color="warning" /> : null} {auth.role!=='user' && lignes.length===0 && panier.mvts.length>0 && <Chip size="small" label={<>Mvts<CheckIcon sx={{fontSize:'1em'}}/></>} color="success" />}
                </Typography>
                <div>
                  <Chip label={status + (panier.status==='pending' ? ' (' + Math.round(p) +'%)' : '')} variant="outlined" color='primary' />
                </div>
              </div>
              <div>
                <Typography variant="body">
                  {auth.role!=='user' ? <small>
                    Créé par {(user && user.name) || panier.user_nom} le {DateTime.fromISO(panier.created_at).toLocaleString(DateTime.DATE_SHORT)}<br />
                  </small>
                  :
                  <small>
                    Créé le {DateTime.fromISO(panier.created_at).toLocaleString(DateTime.DATE_SHORT)}<br />
                  </small>}
                  {panier.status==='done' && panier.done_by && <small>
                    Enlevé par {(salaries.find((o)=>o.id===panier.done_by)||{}).Nom}<br />
                  </small>}
                  <div className='spacer'></div>
                  <span className='produit-key'>Chantier :</span> {chantier ? chantier.Code : panier.chantier_code} - {chantier ? chantier.NomClient : panier.chantier_nom_client} - {chantier ? chantier.Libelle : panier.chantier_nom}
                </Typography>
              </div>
            </CardContent>
          </Card>
        </Grid>;
      })}
      </Grid>
      <div className='spacer'></div>
      {Math.ceil(list.length/itemsPerPage)>1 && <Pagination size='small' count={Math.ceil(list.length/itemsPerPage)} page={page} onChange={handlePageChange}/>}
    </Container>
    <PanierMod panierId={modPanier} onClose={()=>setModPanier(null)}/>
  </>
}

export default Paniers;
