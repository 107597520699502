import React, { useState, useContext, useEffect } from "react";
import "./Paniers.scss";
import Button from '@mui/material/Button';
import Text from 'components/form/Text';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SettingsContext } from "providers/SettingsProvider";
import { ModelContext } from "providers/ModelProvider";

function AddToPanier({produit,handleClose}) {
  const { appState,setPanier } = useContext(SettingsContext);
  const { setMessages } = useContext(ModelContext);
  const [ qte,setQte ] = useState(1);
  const panier=appState.panier;
  const validateQte=(v)=>{
    if (isNaN(v)) return {test:false,message:'Veuillez saisir un nombre > 0'};
    if (v>0) return {test:true};
    else return {test:false,message:'Veuillez saisir un nombre > 0'}
  }
  const valide=validateQte(qte).test;
  const handleOk=()=>{
    if (valide) {
      const ligne={
        produit,
        QTE:parseFloat(qte),
      }
      const idx=panier.lignes.findIndex((o)=>o.produit && o.produit.id===produit.id);
      let lignes;
      if (idx===-1) lignes=[...panier.lignes,ligne];
      else lignes=[
        ...panier.lignes.slice(0,idx),
        {
          ...ligne,
          QTE:parseFloat(ligne.QTE)+parseFloat(panier.lignes[idx].QTE)
        },
        ...panier.lignes.slice(idx+1)
      ]
      setPanier({...panier,lignes});
      handleClose();
      setMessages(state=>{return [...state,{text:'Produit ajouté au panier',severity:'success'}]});
    }
  }
  useEffect(()=>{
    if (produit) setQte(1);
  },[produit,setQte]);
  return <Dialog open={Boolean(produit)} onClose={handleClose} fullWidth maxWidth='xs'>
      <DialogTitle>Ajouter au panier</DialogTitle>
      <DialogContent>
        <div className="mini-spacer"></div>
        <Text type='number' name='Quantité' selectOnFocus value={qte} autoFocus handleChange={(v)=>setQte(Math.max(0,parseFloat(v)))} validate={validateQte} handleSubmit={handleOk}/>
        <div className="mini-spacer"></div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">Annuler</Button>
        <Button disabled={!valide} onClick={handleOk} variant="contained">Ajouter</Button>
      </DialogActions>
    </Dialog>;
}

export default AddToPanier;
