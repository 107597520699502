import React, { useState, useContext, useEffect } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import TextLong from 'components/form/TextLong';
import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

function Message({message,onClose}) {
  const { post } = useContext(ModelContext);
  const { screenSize } = useContext(SettingsContext);
  const [ modMessage, setModMessage ] = useState(null);
  const handleChange=(k,v)=>{
    setModMessage((state)=>{return{...state,[k]:v}});
  }
  const handleSend=()=>{
    if (modMessage) post('messages',modMessage,onClose);
  };
  useEffect(()=>{
    setModMessage(message);
  },[message]);
  return <Dialog
  open={Boolean(message)}
  onClose={onClose}
  fullWidth
  maxWidth={screenSize==='xs' ? undefined : 'xs'}
  fullScreen={screenSize==='xs'}
  PaperProps={{className:"mod-message"}}
  >
      {Boolean(modMessage) && <>
        <DialogTitle>Modifier le message</DialogTitle>
        <DialogContent>
          <div className="send-form">
            <TextLong name='Message' value={modMessage.text} handleChange={(v)=>handleChange('text',v)} endAdornment={<InputAdornment position="end">
              <IconButton onClick={handleSend}>
                <SendIcon />
              </IconButton>
            </InputAdornment>}/>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">Fermer</Button>
        </DialogActions>
      </>}
    </Dialog>;
}

export default Message;
