import React, { useContext } from "react";
import "./Mouvements.scss";
import Container from '@mui/material/Container';
import Schema from 'components/Mouvements/Schema';
import Historique from 'components/Mouvements/Historique';
import Flags from 'components/Mouvements/Flags';
import Chantiers from 'components/Mouvements/Chantiers';
import Totaux from 'components/Mouvements/Totaux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { SettingsContext } from "providers/SettingsProvider";

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function Mouvements() {
  const { appState, setAppState } = useContext(SettingsContext);
  const value = appState.mouvementsTab;
  const handleChange = (event, newValue) => {
    setAppState((state)=>{return {...state, mouvementsTab:newValue}});
  };
  return <>
    <Container maxWidth="xxl" className="mouvements">
      <div className='spacer'></div>
      <Container maxWidth="md" className="mouvements">
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable">
          <Tab label="Saisie des mouvements"/>
          <Tab label="Historique"/>
          <Tab label="Marque-pages"/>
          <Tab label="Chantiers"/>
          <Tab label="Totaux"/>
        </Tabs>
      </Container>
      <div className='spacer'></div>
      <TabPanel value={value} index={0}>
        <Schema/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Historique/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Flags/>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Chantiers/>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Totaux/>
      </TabPanel>
    </Container>
  </>
}

export default Mouvements;
