import React, {useContext, useState, useMemo, useEffect, useRef} from "react";
import "./Messages.scss";
import { ModelContext } from "providers/ModelProvider";
import { AuthContext } from "providers/AuthProvider";
import TextLong from 'components/form/TextLong';
import MessageMod from './MessageMod';
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const scroll=()=>{
  const node=document.querySelector(".mod-panier>div");
  if (node) {
    node.style.scrollBehavior='smooth';
    node.scrollTo(0,node.scrollHeight);
    node.style.scrollBehavior='auto';
  }
}

function Messages({threadId,userId}) {
  const { modele,post,setContexts } = useContext(ModelContext);
  const { auth } = useContext(AuthContext);
  const [ message, setMessage ] = useState('');
  const [ modMessage, setModMessage ] = useState(null);
  const messages=useMemo(()=>modele.messages || [],[modele.messages]);
  const nonlus=useMemo(()=>modele.nonlus || [],[modele.nonlus]);
  const users=useMemo(()=>modele.users || [],[modele.users]);
  const cacheMessages=useRef(null);
  const handleSend=()=>{
    if (message && message.length>0) post('messages',{
        text:message,
        id_thread:threadId,
      },()=>{
      setMessage('');
    });
  };
  const cacheThreadId=useRef(null);
  useEffect(()=>{
    if (cacheThreadId.current!==threadId) {
      setContexts((state)=>{
        const idx=state.findIndex((o)=>o.type==='messages');
        const id_thread=threadId;
        const params={id_thread};
        return [...state.slice(0,idx),{...state[idx],params},...state.slice(idx+1)];
      });
      cacheThreadId.current=threadId;
    }
  },[setContexts, threadId]);
  useEffect(()=>{
    const lus=[];
    messages.forEach((message) => {
      const idx=nonlus.findIndex((o)=>o.messageId===message.id);
      if (idx!==-1) lus.push(message.id);
    });
    if (lus.length>0) post('lus',{
      ids:lus,
    });
  },[messages, nonlus, post]);
  useEffect(()=>{
    if (messages.length>0 && cacheMessages.current && cacheMessages.current.length>0) {
      console.log(messages,cacheMessages.current);
      scroll();
    }
    cacheMessages.current=messages;
  },[messages]);
  return <Container maxWidth="xs" disableGutters>
    <Typography variant="h4" gutterBottom>
      <small className='produit-key'>Discussion :</small>
    </Typography>
    <div className="messages">
      {messages.filter((o)=>o.id_thread===threadId).map((message,i)=><div
        key={message.id}
        className={
          "message"+(message.id_user===auth.id ? ' me':'')
          +(i>0 && message.id_user===messages[i-1].id_user ? ' same':'')
        }
        onClick={message.id_user===auth.id ? ()=>setModMessage(message):undefined}>
        <div className="from">
          {(users.find((o)=>o.id===message.id_user) || {}).name}
          {message.created_at!==message.updated_at && <small> (modifié)</small>}
        </div>
        <div className="text">
          {message.text}
          {message.id_user===auth.id && userId!==message.id_user && message.luBy.indexOf(parseInt(userId))!==-1 && <div className='lu'><CheckIcon style={{fontSize:'0.7em'}}/></div>}
          {message.id_user===auth.id && userId===message.id_user && message.luBy.filter((o)=>o!==userId).length>0 && <div className='lu'><CheckIcon style={{fontSize:'0.7em'}}/></div>}
        </div>
      </div>)}
      {messages.filter((o)=>o.id_thread===threadId).length===0 && "Aucun message"}
      <div className="send-form">
        <TextLong name='Message' value={message} handleChange={setMessage} endAdornment={<InputAdornment position="end">
          <IconButton onClick={handleSend}>
            <SendIcon />
          </IconButton>
        </InputAdornment>}/>
      </div>
    </div>
    <MessageMod message={modMessage} onClose={()=>setModMessage(null)}/>
  </Container>;
}

export default Messages;
