import React, {useState,useContext} from "react";
import "./Mouvements.scss";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ModelContext } from "providers/ModelProvider";
import Text from 'components/form/Text';
const skel={
  nom:'',
}
function AddFlag({ligneId,onClose}) {
  const { post } = useContext(ModelContext);
  const [ flag,setFlag ]=useState(skel);
  const handleChange=(k,v)=>{
    setFlag((state)=>{return{ ...state,[k]:v }});
  }
  const handleSave=()=>{
    console.log('save');
    post('flags',{...flag,id_mouvement:ligneId});
    onClose();
    reset();
  }
  const reset=()=>{
    setFlag(skel);
  }
  const handleClose=()=>{
    reset();
    onClose();
  }
  return <Dialog
    fullWidth
    maxWidth='xs'
    open={Boolean(ligneId)}
    onClose={handleClose}
    className="add-flag"
    >
      <DialogTitle>
        Ajouter un marque-page
      </DialogTitle>
      <DialogContent>
        <Text name='Nom' autoFocus value={flag.nom} handleChange={(v)=>handleChange('nom',v)} handleSubmit={handleSave}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Fermer
        </Button>
        <Button onClick={handleSave} disabled={!flag.nom} variant="contained">
          Ajouter
        </Button>
      </DialogActions>
    </Dialog>;
}

export default AddFlag;
