import React, {useContext, useState, useMemo} from "react";
import "./Produits.scss";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { ModelContext } from "providers/ModelProvider";
import Produit from 'components/Produits/Produit';
import ProduitsForm from 'components/Produits/ProduitsForm';
import ProduitsListe from 'components/Produits/ProduitsListe';
import FamillesChooser from 'components/Produits/FamillesChooser';
import ExportPdf from 'components/Produits/ExportPdf';
import { AuthContext } from "providers/AuthProvider";

function Produits() {
  const { auth } = useContext(AuthContext);
  const { modele } = useContext(ModelContext);
  const [ produitId, setProduitId ] = useState(null);
  const [ empty,setEmpty ] = useState(true);
  const produits=useMemo(()=>[...(modele.produits || [])].sort((a,b)=>a.LibelleStd.localeCompare(b.LibelleStd)),[modele.produits]);
  const familles=useMemo(()=>[...(modele.familles || [])].sort((a,b)=>a.Libelle.localeCompare(b.Libelle)),[modele.familles]);
  const produit=useMemo(()=>produits.find((o)=>o.id===produitId),[produits,produitId]);
  return <>
    <Container maxWidth="xxl" className="produits">
      <div className="spacer"/>
      <Typography variant="h1" component="h1">
        Produits
      </Typography>
      <ProduitsForm/>
      <div className='spacer'></div>
      <FamillesChooser familles={familles}/>
      <div className='spacer'></div>
      {auth.role!=='user' && <div className="actions">
        <ExportPdf disabled={empty}/>
      </div>}
      <div className='spacer'></div>
      <ProduitsListe produits={produits} setProduitId={setProduitId} onEmptyChange={setEmpty}/>
    </Container>
    <Produit produit={produit} onClose={()=>setProduitId(null)}/>
  </>
}

export default Produits;
