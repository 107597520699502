import React from "react";
import "./Color.scss";
import { ColorPicker } from 'material-ui-color';

function Color({name='',value,handleChange,onClose=()=>{}}) {
  const handleOpen=(open)=>{
    console.log(open);
    if (!open) onClose();
  }
  return (
    <div className={"input-color"}>
      <ColorPicker
      hideTextfield
      defaultValue="transparent"
      fullWidth
      label={name}
      variant="outlined"
      value={value || '#f000'}
      disableAlpha
      onChange={(e)=>handleChange('#'+e.hex)}
      onOpen={handleOpen}
      />
    </div>
  )
}

export default Color;
