import React, {useContext, useMemo, useState,useCallback } from "react";
import "./Mouvements.scss";
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import { DataGrid, frFR } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { saveAs } from 'file-saver';
import CircularProgress from '@mui/material/CircularProgress';
import Utils from 'Utils/Utils';
import { DateTime } from "luxon";
import Conf from 'Conf';

function HistoriqueListe({columns,setSelected,selected}) {
  const [ xlsRunning, setXlsRunning ] = useState(false);
  const { post,modele } = useContext(ModelContext);
  const { appState,setAppState } = useContext(SettingsContext);
  const columnVisibilityModel=useMemo(()=>appState.columnVisibilityModel,[appState.columnVisibilityModel]);
  const filterModel=useMemo(()=>appState.filterModel,[appState.filterModel]);
  const setColumnVisibilityModel=useCallback((columnVisibilityModel)=>setAppState((state)=>{
    return { ...state, columnVisibilityModel};
  }),[setAppState]);
  const mouvements=useMemo(()=>{
    let even=true;
    return [...(modele.mouvements || [])].filter((o)=>{
      for(const filter of filterModel.items) {
        if(filter.key && filter.value!=='') {
          if(o[filter.key]){
            switch (filter.key) {
              case 'created_at':
                if(Utils.accentsTidyLw(DateTime.fromISO(o[filter.key], { zone: "utc" }).toLocaleString(DateTime.DATETIME_SHORT)).indexOf(Utils.accentsTidyLw(filter.value))===-1) return false;
                break;
              case 'MVT':
                if(Utils.accentsTidyLw(Conf.mvts.find((mvt)=>mvt.value===o[filter.key]).labelShort).indexOf(Utils.accentsTidyLw(filter.value))===-1) return false;
                break;
              default:
                if(Utils.accentsTidyLw(o[filter.key]+'').indexOf(Utils.accentsTidyLw(filter.value))===-1) return false;
            }
          } else {
            return false;
          }
        }
      }
      return true;
    }).map((o,i,tab)=>{
      if (i>0 && tab[i-1].created_at!==o.created_at) even=!even;
      return {...o,kind:even ? 'even' : 'odd'}
    }).map((o,i,tab)=>{
      const lastEven=tab[tab.length-1].kind === 'even' ;
      const even=lastEven ? o.kind === 'even' : o.kind === 'odd';
      return {...o,kind:even ? 'even' : 'odd'}
    });
  },[modele.mouvements,filterModel]);
  const etatXlsx=useCallback(()=>{
    setXlsRunning(true);
    post('historique-xlsx',{filterModel},(res)=>{
      setXlsRunning(false);
      var blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      saveAs(blob, `etat-hitorique.xlsx`);
    },true,'blob');
  },[post,setXlsRunning,filterModel]);
  console.log('render liste');
  const renderClass=useCallback((params) => `historique-mvt-${params.row.kind}`+(selected && params.row.annule===selected?' revert':'')+(selected && params.row.id===selected?' reverted':''),[selected]);
  return <>
    <Button variant="contained" onClick={etatXlsx}>
      XLSX {xlsRunning && <CircularProgress size={16} style={{marginLeft:10,color:'#FFF'}}/>}
    </Button>
    <div className='spacer'></div>
    <div style={{ minHeight: 'calc(100vh - 68px - 112px - 30px)', height:600,width: '100%' }}>
      <DataGrid
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={setColumnVisibilityModel}
        rows={mouvements}
        columns={columns}
        onCellClick={setSelected}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        getRowClassName={renderClass}
        disableColumnFilter
      />
    </div>
  </>
}

export default HistoriqueListe;
