import React, {useMemo} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { DateTime } from "luxon";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Consommation',
    },
  },
};

const labels=[];
for(let i=0;i<12;i++) {
  const date=DateTime.now().minus({ months: 11-i }).toFormat('yyyy-LL');
  labels.push(date);
}

function Historique({historique}) {
  const dataset=useMemo(()=>labels.map((label)=>(historique.find((o)=>o.mois===label)||{qte:0}).qte),[historique]);
  const data=useMemo(()=>{return{
    labels,
    datasets:[{
      backgroundColor: 'rgb(255, 99, 132)',
      borderColor: 'rgb(255, 99, 132)',
      data:dataset,
    }]
  }},[dataset]);
  return <Bar options={options} data={data} />;
}

export default Historique;
