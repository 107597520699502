import React, {useContext, useMemo }from "react";
import "./Mouvements.scss";
import Container from '@mui/material/Container';
import { ModelContext } from "providers/ModelProvider";
import Autocomplete from 'components/form/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { saveAs } from 'file-saver';
import Button from '@mui/material/Button';
import { SettingsContext } from "providers/SettingsProvider";

function Chantiers() {
  const { post,modele } = useContext(ModelContext);
  const { appState, setAppState } = useContext(SettingsContext);
  const chantiers=useMemo(()=>[...(modele['stock-chantiers'] || [])].sort((a,b)=>b.key-a.key),[modele]);
  const chantier = useMemo(()=>(chantiers && chantiers.find((o)=>o.id===appState.chantierId)) || (chantiers && chantiers[0]) || {},[appState.chantierId,chantiers]);
  const handleChange=(v)=>{
    setAppState((state)=>{return {...state, chantierId:v ? v.id : null}});
  }
  const etatXlsx=(chantier)=>{
    post('chantier-xlsx',{chantier:chantier.key},(res)=>{
      var blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      saveAs(blob, `chantier-stock-${chantier.key}.xlsx`);
    },true,'blob');
  }
  return <>
        <div className='spacer'></div>
        <Container maxWidth="md">
          <Autocomplete name='Chantier' value={chantier} handleChange={handleChange} options={chantiers} labelKey={'label'}/>
          {chantier && <>
            <Button variant="contained" onClick={()=>etatXlsx(chantier)}>XLSX</Button>
            <div className='spacer'></div>
          </>}
          {chantier && chantier.values && <TableContainer component={Paper}>
            <Table aria-label="Stock Chantier">
             <TableHead className="chantier-lignes-header">
               <TableRow>
               <TableCell>Produit</TableCell>
               <TableCell>Ref.</TableCell>
               <TableCell>Fabricant</TableCell>
               <TableCell>Famille</TableCell>
               <TableCell align="right">Quantité</TableCell>
               </TableRow>
             </TableHead>
             <TableBody>
               {chantier.values.map((stock,i) => {
               return <TableRow
                   key={i}
                   sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                 >
                   <TableCell component="th" scope="row">
                     {stock.Libelle}
                   </TableCell>
                   <TableCell>
                     {stock.CODE}
                   </TableCell>
                   <TableCell>
                     {stock.Fabricant}
                   </TableCell>
                   <TableCell>
                    {stock.Famille}
                   </TableCell>
                   <TableCell sx={{width:'1em'}} align="right">
                    {parseFloat(stock.qte)}
                   </TableCell>
                 </TableRow>;
               })}
               </TableBody>
             </Table>
           </TableContainer>}
        </Container>
  </>;
}

export default Chantiers;
