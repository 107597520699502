import React, {useContext, useState } from "react";
import "./Mouvements.scss";
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { SettingsContext } from "providers/SettingsProvider";
import Mouvement from 'components/Mouvements/Mouvement';
import Conf from 'Conf';

const skel={
    MVT:'',
    Fournisseur:null,
    Chantier:null,
    Salarie:null,
}
function Schema() {
  const [ newItem,setNewItem ]=useState(null);
  const { screenSize } = useContext(SettingsContext);
  return <>
    <div className='big-spacer'></div>
    <Container maxWidth="md">
      {['xs','sm','md'].indexOf(screenSize)===-1 && <div className="canvas">
        <div className="canvas-inner">
          <div className="chantiers"/>
          <div className="depot"/>
          <div className="fournisseurs"/>
          <div className="chantiers-label">Chantiers</div>
          <div className="depot-label">Dépôt</div>
          <div className="fournisseurs-label">Fournisseurs</div>
          {Conf.mvts.map((mvt)=><div key={mvt.value} className={mvt.value.replace(' ','-')}>
            {mvt.value!=='MVT INVENTAIRE' && <div className="fleche"></div>}
            <Button variant="contained" onClick={()=>setNewItem({...skel, MVT:mvt.value})}>{mvt.label}</Button>
          </div>)}
        </div>
      </div>}
      {['xs','sm','md'].indexOf(screenSize)!==-1 && <div className="mvts-sm">
      {Conf.mvts.map((mvt)=><div key={mvt.value} className={mvt.value.replace(' ','-')+' mvts-btns'}>
        <Button variant="contained" onClick={()=>setNewItem({...skel, MVT:mvt.value})}>{mvt.label}</Button>
      </div>)}
      </div>}
    </Container>
    <Mouvement newItem={newItem} onClose={()=>setNewItem(null)}/>
  </>
}

export default Schema;
