import React, {useContext, useState, forwardRef, useCallback} from "react";
import "./Salaries.scss";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { ModelContext } from "providers/ModelProvider";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import SalarieAdd from 'components/Salarie/SalarieAdd';
import SalarieMod from 'components/Salarie/SalarieMod';
import { confirm } from 'components/Confirm'
import Fab from '@mui/material/Fab';
import Sortable from 'components/Sortable';

const CustomGrid = forwardRef((props, ref) => {
  return <Grid container spacing={2} ref={ref}>{props.children}</Grid>;
});


function Salaries() {
  const { modele,del,post } = useContext(ModelContext);
  const [ modSalarie, setModSalarie ] = useState(null);
  const [ add, setAdd ] = useState(false);
  const salaries=[...(modele.salaries || [])].sort((a,b)=>a.Sort-b.Sort);
  const handleAddSalarie=()=>{
    setAdd(true);
  }
  const handleSort=(list)=>{
    post('salaries|salariesBulk',{salaries:list.filter((o)=>{
      const item=salaries.find((f)=>f.id===o.id);
      return item.Sort!==o.Sort;
    })});
  }
  const tile=useCallback((salarie)=>{
    const handleDeleteSalarie=(salarie)=>{
      confirm({
        titre: 'Supprimer le salarié ?',
        confirmation: <>Êtes vous sûr de vouloir supprimer <strong>{salarie.Nom}</strong>&nbsp;?</>,
      }).then((ok)=>{
        if (ok) del('salaries',salarie.id);
        else console.log('canceled');
      });
    };
    return <Grid key={salarie.id} item xs={6} md={2}>
      <Card>
        <CardContent className="sort-handle salarie-content">
          <Typography variant="body">
            {salarie.Nom || 'Sans nom'}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={()=>setModSalarie(salarie)}>Modifier</Button>
          <Button size="small" onClick={()=>handleDeleteSalarie(salarie)}>Supprimer</Button>
        </CardActions>
      </Card>
    </Grid>;
  },[setModSalarie,del]);
  return <>
    <Container maxWidth="lg" className="salaries">
      <div className="spacer"/>
      <Typography variant="h1" component="h1">
        Salariés
      </Typography>
      <div className='spacer'></div>
      <Sortable wrapper={CustomGrid} list={salaries} onChange={handleSort} render={tile}/>
    </Container>
    <div className="floating-action">
      <Container maxWidth="lg">
      <Fab color="primary" aria-label="add" onClick={handleAddSalarie}>
        <AddIcon />
      </Fab>
      </Container>
    </div>
    <SalarieAdd open={add} handleClose={()=>setAdd(false)}/>
    <SalarieMod salarie={modSalarie} handleClose={()=>setModSalarie(null)}/>
  </>
}

export default Salaries;
