import React, {useContext, useMemo, useCallback, useRef, useEffect} from "react";
import "./Produits.scss";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SettingsContext } from "providers/SettingsProvider";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Utils from 'Utils/Utils';
import Image from 'components/Image';
import Pagination from '@mui/material/Pagination';
import StockChip from './StockChip';

const itemsPerPage=48;

function ProduitsListe({produits,setProduitId,onEmptyChange}) {
  const { appState, setAppState } = useContext(SettingsContext);
  const appStateFilter=useMemo(()=>appState.produitsFilter ,[appState.produitsFilter]);
  const appStateFamilles=useMemo(()=>appState.produitsFamilles ,[appState.produitsFamilles]);
  console.log(appStateFamilles);
  const page=useMemo(()=>appState.produitsPage ,[appState.produitsPage]);
  const paginationTop=useRef(null);
  const handlePageChange=useCallback((e,p)=>{
    setAppState((state)=>{return{...state,produitsPage:p}});
    if(paginationTop.current) paginationTop.current.scrollIntoView();
  },[setAppState]);
  const { mode, produitsStock } = appState;
  const filteredProduits=useMemo(()=>{
    let tab = (appStateFilter ? appStateFilter.split(' ') : []).filter((o)=>o!=='').map(word=>Utils.accentsTidyLw(word));
    return produits.filter((o)=>{
    let test = mode==='and' ? true : (tab.length>0 ? false : true);
    for (const word of tab) {
      if (mode==='and') {
        test = test && (
          Utils.accentsTidyLw(o.LibelleStd||'').indexOf(word) !==-1
          || Utils.accentsTidyLw(o.FABRIQUANT||'').indexOf(word) !==-1
          || Utils.accentsTidyLw(o.CODEEAN||'').indexOf(word) !==-1
          || Utils.accentsTidyLw(o.Code||'').indexOf(word) !==-1
        );
      } else {
        test = test || (
          Utils.accentsTidyLw(o.LibelleStd||'').indexOf(word) !==-1
          || Utils.accentsTidyLw(o.FABRIQUANT||'').indexOf(word) !==-1
          || Utils.accentsTidyLw(o.CODEEAN||'').indexOf(word) !==-1
          || Utils.accentsTidyLw(o.Code||'').indexOf(word) !==-1
        );
        if (test) break;
      }
    };
    let familleTest=false;
    let familleEmpty=appStateFamilles.length===0;
    appStateFamilles.forEach((famille) => {
      familleTest=familleTest||o.Famille===famille.Code;
    });
    let stockTest=true;
    if (produitsStock==='stock') stockTest=o.stock>0;
    if (produitsStock==='outOfStock') stockTest=!o.stock || o.stock<=0;
    if (produitsStock==='negative') stockTest=o.stock<0;
    return test && (familleEmpty || familleTest) && stockTest;
  })},[produits,appStateFilter,appStateFamilles,mode,produitsStock]);
  useEffect(()=>{
    onEmptyChange && onEmptyChange(filteredProduits.length===0);
  },[filteredProduits,onEmptyChange]);
  const nb=useMemo(()=>Math.ceil(filteredProduits.length/itemsPerPage),[filteredProduits]);
  return <>
    <Pagination ref={paginationTop} size='small' count={nb} page={page} onChange={handlePageChange}/>
    <div className='spacer'></div>
    <Grid container spacing={2}>
      {filteredProduits.map((produit,i)=>{
        return (i>=(page-1)*itemsPerPage && i<page*itemsPerPage) ?
        <Grid key={produit.id} item xs={6} sm={4} md={3} lg={2}>
          <Card className='produit-card' onClick={()=>setProduitId(produit.id)}>
            <CardContent sx={{position:'relative'}}>
              <Image url={Utils.chemin2url(produit.CheminImage)}/>
              <StockChip produit={produit}/>
              <div className='spacer'></div>
              <Typography variant="h3" gutterBottom>
                {produit.LibelleStd || '(Sans nom)'}
              </Typography>
              <Typography variant="h4" gutterBottom>
                {produit.FABRIQUANT}
              </Typography>
              <Typography variant="h4" gutterBottom>
                {produit.Code}
              </Typography>
              {produit.Couleur && <div className='color-spot' style={{backgroundColor:produit.Couleur}}></div>}
            </CardContent>
          </Card>
        </Grid> : null ;
      })}
    </Grid>
    <div className='spacer'></div>
    <Pagination size='small' count={nb} page={page} onChange={handlePageChange}/>
  </>;
}

export default ProduitsListe;
